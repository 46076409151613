.contact-infos__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contact-infos__container__item {
  width: 100%;

  @media screen and (min-width: $mq-tablet-l) {
    width: calc(50% - 1rem);
  }
}

.contact-infos__ls {
  @media screen and (min-width: $mq-tablet-l) {
    max-width: 500px;
    margin-right: 2rem;
  }
}

.contact-infos__map {
  flex-grow: 1;
}

.contact-infos__item {
  display: flex;
  // justify-content: space-between;
  align-items: center;
  padding: 2rem 0;
  font-family: $ff-title;
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0.05rem;
  
  &:not(:last-of-type) {
    border-bottom: 1px solid $c-dark;
  }

  @media screen and (min-width: $mq-tablet-m) {
    font-size: 1.8rem;
  }
}

.contact-infos__item__title {
  width: 25rem;
  @extend .title-7;
  font-size: 1.4rem;
  line-height: 1;
  margin-bottom: 0;

  @media screen and (min-width: $mq-tablet-m) {
    font-size: 1.8rem;  
  }
}

.contact-infos__social-link {
  width: 30px;
  height: 30px;

  &:first-of-type {
    margin-right: .5rem;
  }

  &:hover svg {
    fill: $c-linkedin;
  } 
  
  &:hover .st0 {
    fill: $c-facebook;
  }
}

.contact__form  .contact-form__inputs-container, .form-container__inputs-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.contact__form__input-container, .form-container__input-container {
  label {
    margin-bottom: 1.5rem;
    @extend .contact-infos__item__title;
    font-weight: 600;
  }

  input, textarea {
    margin-bottom: 3rem;
    border: 3px solid $gray-lightest;
    padding: 1.5rem;
    @extend .contact-infos__item__title;
    text-transform: initial;
  }

  input {
    width: 100%;
  }
}

.contact__form__input-container {
  width: 100%;

  &:not(:last-of-type) {
    @media screen and (min-width: $mq-tablet-l) {
      width: calc(100% / 3 - 2rem);
    }
  }

  textarea[name="message"] {
    width: 100%;
  }
}

.contact__form__submit-btn, .wpcf7-submit {
  margin-left: auto;
}

.wpcf7-submit {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 5rem;
  margin-bottom: $margin-bot-btn;
  border: $border-style;
  padding: 1rem 2rem;
  border-radius: 8px;
  border-top-left-radius: 0;
  font-family: $ff-title;
  font-size: 1.6rem;
  font-weight: 600;
  color: $c-dark;
  transition: all .3s $ease-in-out;
  border-color: $c-halfbaked;
  color: $c-light;
  background: $c-halfbaked;

  @media screen and (min-width: $mq-tablet-s) {
    width: 210px;
  }

  &::after {
    background: url('./../img/arrow-sm-btn-wit.svg') center/cover;
  }

  &:hover {
    color: $c-dark;
    // color: $c-halfbaked;
    background: $c-light;

    &::after {
      background: url('./../img/arrow-sm-btn.svg') center/cover;
      // background: url('./../img/arrow-sm-btn-halfbaked.svg') center/cover;
    }
  }
}

.gdpr {
  font-family: $ff-title;
  font-size: 1.5rem;

  .wpcf7-list-item {
    margin-left: 0;
  }
}