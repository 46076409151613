@import url('https://fonts.googleapis.com/css?family=EB+Garamond:400,400i,500,700,700i|Work+Sans:300,400,500,600|Zilla+Slab:400,500,600'); 

[class*="title-1"], .title-1,
[class*="title-2"], .title-2,
[class*="title-3"], .title-3,
[class*="title-4"], .title-4,
[class*="title-5"], .title-5,
[class*="title-6"], .title-6,
[class*="title-6"], .title-7 {
  margin-bottom: 2rem;
  font-family: $ff-title;
  line-height: 1.2;
}

[class*="title-1"], .title-1, h1 {
  @extend %text-uppercase;
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1;

  @media screen and (min-width: $mq-tablet-m) {
    font-size: 6rem;
  }
}

[class*="title-2"], .title-2, h2 {
  font-size: 2rem;
  font-weight: 600;

  @media screen and (min-width: $mq-tablet-m) {
    font-size: 3rem;
  }
}

[class*="title-2"][class*="--big"] {
  font-size: 4rem;

  @media screen and (min-width: $mq-tablet-m) {
    font-size: 6rem;
  }

  @media screen and (min-width: $mq-desktop-s) {
    font-size: 10rem;
  }
}

[class*="title-3"], .title-3, h3 {
  margin-bottom: .5rem;
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 0.2rem;
}

[class*="title-4"], .title-4, h4 {
  font-size: 3.5rem;
  font-weight: 300;
  letter-spacing: 0.2rem;
  margin-bottom: $article_titles-margin-b;

  @media screen and (min-width: $mq-tablet-m) {
    font-size: 5rem;
  }
}

[class*="title-5"], .title-5, h5 {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 3rem;

  @media screen and (min-width: $mq-tablet-m) {
    font-size: 4rem;
  }

  @media screen and (min-width: $mq-desktop-m) {
    font-size: 6rem;
  }
}

[class*="title-6"], .title-6, h6 {
  position: relative;
  display: inline-block;
  margin-bottom: 5rem;
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
}

.title-6 {
  &::after {
    content: '';
    position: absolute;
    bottom: -8px;
    display: block;
    width: 100%;
    height: 3px;
    background: $black;
  }

  &::before {
    content: '';
    position: absolute;
    display: block;
    z-index: 10;
    bottom: calc( -8px - 14px );
    right: 20%;
    width: 17px;
    height: 17px;
    background: url('./../img/arrow-bot-title.svg') center/cover;
  }

  &--gray {
    &::before {
      background: url('./../img/arrow-bot-title_gray.svg') center/cover;
    }
  }
}

[class*="title-7"], .title-7 {
  @extend %text-uppercase;
  display: block;
  font-size: 2rem;
  font-weight: 300;
  letter-spacing: .1rem;
  line-height: 1;
  margin-bottom: 0;

  @media screen and (min-width: $mq-tablet-m) {
    font-size: 2.6rem;
  }
}

.title-7--big {
  font-size: 3rem;

  @media screen and (min-width: $mq-tablet-m) {
    font-size: 4.3rem;
  }
}

.title-7--small {
  font-size: 1.8rem;

  @media screen and (min-width: $mq-tablet-m) {
    font-size: 2rem;
  }

  @media screen and (min-width: $mq-desktop-m) {
    font-size: 2.4rem;
  }
}

.title-7--xs {
  font-size: 1.8rem;

  @media screen and (min-width: $mq-tablet-m) {
    font-size: 2rem;
  }

  @media screen and (min-width: $mq-desktop-m) {
    // font-size: 2.4rem;
  }
}

[class*="title-8"], .title-8 {
  font-size: 1.6rem;

  @media screen and (min-width: $mq-tablet-m) {
    font-size: 2rem;
  }
}

.title-product {
  font-family: $ff-title;
  font-size: 2rem;
  background: $c-snuff;
}


.excerpt {
  font-family: $ff-title;
  font-size: 1.8rem;
  font-weight: normal;
  line-height: 2.4rem;
}

[class*="bodytext"] {
  font-family: $ff-bodytext;
  font-size: 1.6rem;

  @media screen and (min-width: $mq-mobile-m) {
    font-size: 1.8rem;
  }

  @media screen and (min-width: $mq-desktop-m) {
    font-size: 2.1rem;
  }

  strong, b {
    font-weight: 600;
  }
}

[class*="bodytext--small"][class*="-small"] {
  font-family: $ff-bodytext;
  font-size: 1.8rem;

  @media screen and (min-width: $mq-desktop-m) {
    font-size: 1.8rem;
  }
}

[class*="bodytext--"][class*="--heading"],
[class*="bodytext--"][class*="--heading"] p {
  font-family: $ff-alt;  
  font-size: 2rem;
  margin-bottom: $spacing * 2;
  transition: font-size .3s $ease-in-out;

  strong, b {
    font-weight: 600;
  }

  @media screen and (min-width: $mq-desktop-s) {
    margin-bottom: $spacing * 3;
    font-size: 2.4rem;
  }
}

.font-infos { 
  font-family: $ff-alt;
  font-size: 1.3rem;
  font-weight: 800;
  line-height: 1;

  @media screen and (min-width: $mq-mobile-l) {
    font-size: 1.5rem;
  }

  @media screen and (min-width: $mq-tablet-m) {
    font-size: 1.8rem;
  }
}

.echo__text {
  font-family: $ff-alt;
  font-size: 1.6rem;

  @media screen and (min-width: $mq-tablet-m) {
    font-size: 1.8rem;
  }
}

.echo__person {
  font-family: $ff-bodytext;
  font-size: 1.6rem;
}
