[class*="input--"][class*="--search"] {
  display: flex;
  min-width: 20rem;
  max-width: 30rem;
  margin-bottom: $margin-bot-btn;
  border: $border-style;
  padding: .5rem 1rem;
  font-family: $ff-title;
  font-size: 1.6rem;
  font-weight: 600;
  color: $c-dark;
  border-radius: 10px;

  input {
    display: block;
    flex-grow: 1;
    border: 0;
    background: transparent;
  }
}

[class*="input--"][class*="--search"] {
  max-height: 46px;
  border: $border-style;
  flex-basis: 100%;
  max-width: 40rem;

  

  &[class*="--light"] {
    border-color: $c-light;

    &::before {
      content:'';
      display: block;
      width: 20px;
      height: 20px;
      margin-right: 1rem;
      background: url('./../img/search-icon.svg') center/cover;
      transform: translateY(3px);
      background: url('./../img/search-icon-wit.svg') center/cover;
      transform: translateY(5px);
      flex-shrink: 0;
    }

    input {
      color: $c-light;
      width: calc(100% - 4rem);

      &:focus {
        outline: 0;
      }
    }
  }

  &[class*="--dark"] {
    border-color: $c-dark;
    margin-bottom: 0;

    input {
      color: $c-dark;
      width: calc(100% - 4rem);

      &:focus {
        outline: 0;
      }
    }
  }
}