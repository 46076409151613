$stc__item-margin: 4rem;
$stc-height-desktop: 900px;

.section-three-commitments {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  @media screen and (min-width: $mq-tablet-m) {
    flex-direction: row;
    margin-bottom: $stc__item-margin;
  }

  @media screen and (min-width: $mq-desktop-s) {
    flex-direction: column;
    max-height: $stc-height-desktop;
  }

  &__item {
    width: 100%;
    min-height: 400px;
    margin-bottom: calc(#{$stc__item-margin} / 2);

    @media screen and (min-width: $mq-tablet-m) {
      margin-bottom: calc(#{$stc__item-margin});
    }

    @media screen and (min-width: $mq-desktop-s) {
      width: 70%;
      height: calc(50% - #{$stc__item-margin} / 2);
      min-height: auto;
      margin-bottom: 0;
      flex-grow: 1;
    }
  }

  // Premier element
  &__item:first-of-type { // same el as single-block
    @media screen and (min-width: $mq-desktop-s) {
      width: 30%;
      height: 100%;
      min-height: 650px;
      margin-right: $stc__item-margin;
    }
  }

  // Tous les éléments sauf le premier
  &__item:not(:first-of-type) { 
    @media screen and (min-width: $mq-tablet-m) {
      width: calc(50% - #{$stc__item-margin} / 2);
      margin-bottom: 0;
    }

    @media screen and (min-width: $mq-desktop-s) {
      width: calc(70% - #{$stc__item-margin});
    }
  }

  // 2e element
  &__item:nth-of-type(2) {
    @media screen and (min-width: $mq-tablet-m) {
      margin-right: $stc__item-margin;
    }

    @media screen and (min-width: $mq-desktop-s) {
      margin-bottom: $stc__item-margin;
    }
  }

  // 3e et dernier elemnt
  &__item:last-of-type { 
    margin-bottom: calc(#{$stc__item-margin});

    @media screen and (min-width: $mq-tablet-m) {
      margin-bottom: 0;
    }
  }

  // Style sur les 2 blocks qui se renversent
  .single-block:not(:first-of-type) {
    .single-block__infos {
      @media screen and (min-width: $mq-desktop-s) {
        flex-basis: 30%;
      }
    }

    .single-block__about {
      @media screen and (min-width: $mq-desktop-s) {
        flex-basis: 70%;
      }
    }
  }
}

// Révision des border-radius lorsque le bloc se renverse
@media screen and (min-width: $mq-desktop-s) {
  .single-block--h {
    .single-block__infos,
    .single-block__infos__bg,
    .single-block__infos__bg::after {
      border-bottom-left-radius: $sb-cpnt-border-radius;
      border-top-right-radius: 0;
    }

    .single-block__about {
      border-top-right-radius: $sb-cpnt-border-radius;
      border-bottom-right-radius: $sb-cpnt-border-radius;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.section-three-commitments.section-last-review {
  .single-block__infos__bg {
    border-radius: 0;
  }

  .section-three-commitments__item {
    width: 100%;
  }

  .section-three-commitments__item:first-of-type { // same el as single-block
    @media screen and (min-width: $mq-desktop-s) {
      min-height: auto;
    }
  }

  @media screen and (min-width: $mq-tablet-m) {
   .single-block__infos {
      flex-basis: 30%;
      border-top-right-radius: 0;
      border-bottom-left-radius: 2rem;
      overflow: hidden;
    }

    .single-block__about {
      width: 70%;
      flex-basis: auto;
      flex-grow: 1;
      border-bottom-left-radius: 0;
      border-top-right-radius: 2rem;
    } 
  }
}