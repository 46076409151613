.sb-cpnt__categories {
  margin-bottom: $sb-cpnt-spacing;
  padding-bottom: 2rem;
  border-bottom: $border-style;
}

.sb-cpnt__categories__el {
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
  // flex-grow: 1;
}

.sb-cpnt__categories__link {
  display: block;
  width: 100%;
  border-radius: .8rem;
  padding: .5rem 2rem;
  font-family: $ff-alt;
  font-size: 1.8rem;
  font-weight: normal;
  background: $c-snuff;
  text-align: center;
  transition: all .3s $ease-in-out;

  &:hover {
    color: $white;
    background: $c-wistful;
  }
}