$section-last__title-margin: 4rem;
$section-last__head-margin: 3rem;
$section-last__content-margin: 3rem;
$section-last__bodytext-last_p-margin: 3rem;
$section-last__img-width: 40%;
$section-last__img-width-small: 40%;
$section-last__img-margin: 4rem;
$section-last__img-margin-small: 3rem;
$section-last__img-minheight-mobile: 16rem;
$section-last__img-minheight: 25rem;
$section-last__img_smaller-minheight-mobile: 10rem;
$section-last__img_smaller-minheight: 25rem;
$section-last__img_smaller_bodytext-last_p-margin: 2rem;
$divider-img-smaller: 1.2;

[class*="section-last"] {
  .section-last__title {
    margin-bottom: #{$section-last__title-margin};  
  }

  .section-last__head {
    margin-bottom: #{$section-last__head-margin};
  }
  
  .section-last__content {
    margin-bottom: #{$section-last__content-margin};
  
    @media screen and (min-width: $mq-tablet-m) {
      margin-bottom: calc(#{$section-last__content-margin} + 1rem);
    }
  }

  .section-last__bodytext {
    margin-bottom: #{$section-last__bodytext-last_p-margin};
    font-size: 1.8rem;
  
    @media screen and (min-width: $mq-tablet-m) {
      font-size: 2.1rem;
    }
  
  }

  .section-last__img {
    flex-basis: 100%;
    min-height: $section-last__img-minheight-mobile;
    margin-bottom: #{$section-last__img-margin};
  
    @media screen and (min-width: $mq-mobile-l) {
      min-height: #{$section-last__img-minheight};
    }
  
    @media screen and (min-width: $mq-tablet-l) {
      flex-basis: calc(#{$section-last__img-width} - #{$section-last__img-margin});
      margin-right: #{$section-last__img-margin};
      margin-bottom: 0;
    }
  }
  
  .section-last__text {
    flex-basis: 100%;
    
    @media screen and (min-width: $mq-tablet-m) {
      display: flex;
      flex-flow: row wrap;
    }
  
    @media screen and (min-width: $mq-tablet-l) {
      flex-basis: calc(100% - #{$section-last__img-width});
    }
  }

  .section-last__cta {
    @media screen and (min-width: $mq-tablet-m) {
      align-self: end;
      margin-bottom: 0;
    }
  }
}

[class*="section-last--"][class*="--publication"] {
  .section-last__img {
    flex-basis: 100%;
    min-height: #{$section-last__img-minheight-mobile};
    margin-bottom: #{$section-last__img-margin-small};
  
    @media screen and (min-width: $mq-mobile-l) {
      min-height: #{$section-last__img-minheight};
    }
  
    @media screen and (min-width: $mq-tablet-m) {
      flex-basis: calc(#{$section-last__img-width-small} - #{$section-last__img-margin-small});
      margin-right: #{$section-last__img-margin-small};
      margin-bottom: 0;
    }
  
    @media screen and (min-width: $mq-tablet-l) {
      flex-basis: 100%;
      margin-right: 0;
      margin-bottom: #{$section-last__img-margin-small};
    }
  
    @media screen and (min-width: $mq-desktop-s) {
      flex-basis: calc(#{$section-last__img-width-small} - #{$section-last__img-margin-small});
      margin-right: #{$section-last__img-margin-small};
      margin-bottom: 0;
    }
  }
  
  .section-last__text {
    flex-basis: 100%;
  
    @media screen and (min-width: $mq-tablet-m) {
      flex-basis: calc(100% - #{$section-last__img-width-small});
    }
  
    @media screen and (min-width: $mq-tablet-l) {
      flex-basis: 100%;
    }
  
    @media screen and (min-width: $mq-desktop-s) {
      flex-basis: calc(100% - #{$section-last__img-width-small});
    }
  }

  &[class*="--img-smaller"] {
    .section-last__img {
      @media screen and (min-width: $mq-mobile-l) {
        min-height: #{$section-last__img_smaller-minheight-mobile};
      }

      @media screen and (min-width: $mq-mobile-l) {
        min-height: #{$section-last__img_smaller-minheight};
      }
      
      @media screen and (min-width: $mq-tablet-m) {
        flex-basis: calc(#{$section-last__img-width} / #{$divider-img-smaller} - #{$section-last__img-margin});
        margin-right: #{$section-last__img-margin};
      }

      @media screen and (min-width: $mq-tablet-l) {
        flex-basis: 100%;
        margin-right: 0;
        margin-bottom: #{$section-last__img-margin};
      }
    
      @media screen and (min-width: $mq-desktop-m) {
        flex-basis: calc(#{$section-last__img-width} / #{$divider-img-smaller} - #{$section-last__img-margin});
        margin-right: #{$section-last__img-margin};
        margin-bottom: 0;
      }
    }  

    .section-last__text {
      @media screen and (min-width: $mq-tablet-m) {

        flex-basis: calc(100% - #{$section-last__img-width} / #{$divider-img-smaller});
      }

      @media screen and (min-width: $mq-tablet-l) {
        flex-basis: 100%;
      }

      @media screen and (min-width: $mq-desktop-m) {
        flex-basis: calc(100% - #{$section-last__img-width} / #{$divider-img-smaller});
      }
    }

    .section-last__bodytext {
      p:last-of-type {
        margin-bottom: #{$section-last__img_smaller_bodytext-last_p-margin};
      }
    }
  }
}

[class*="section-last--"][class*="--dossier"] {
  .last-dossier__flag {
    display: inline-block;
    padding: .5rem 1rem;
    margin-bottom: 1rem;
    background: $c-starship;
    font-size: 1.4rem;
    font-weight: bold;
    text-transform: uppercase;

    @media screen and (min-width: $mq-tablet-m) {
      font-size: 1.8rem;
    }
  }

  .last-dossier__edito {
    margin-bottom: 4rem;
    padding: 2rem 0;
    border-top: $border-style;
    border-bottom: $border-style;
    border-color: $c-snuff;
    cursor: pointer;
  }

  .last-dossier__edito__title {
    position: relative;
    font-size: 2.5rem;
    margin-bottom: 1rem;

    @media screen and (min-width: $mq-tablet-m) {
      font-size: 2.8rem;
    }

    @media screen and (min-width: $mq-desktop-m) {
      font-size: 3.2rem;
    }

    &::after {
      position: absolute;
      right: 0;
      top: 0;
      transform: translateY(50%) rotate(90deg);
      display: block;
      content: '';
      width: 10px;
      height: 15px;
      background: url('./../img/arrow-sm-btn.svg') center/cover;
      transition: .3s transform $ease-in-out;
    }
  }

  .last-dossier__edito.active {
    .last-dossier__edito__title {
      &::after {
        transform: translateY(50%) rotate(-90deg);
      }
    }
  }

  .last-dossier__edito__author {
    font-family: $ff-bodytext;
    font-size: 1.6rem;
    margin-bottom: 1rem;

    @media screen and (min-width: $mq-desktop-m) {
      font-size: 2rem;
    }
  }

  .last-dossier__edito__content {
    display: none;

    p:last-of-type {
      margin-bottom: 2rem;
    }
  }

  .last-magazine__cta {
    display: block;
  }

  .dossier-articles__ls {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .dossier-articles__item__title {
    margin-bottom: 1rem;
  }

  .dossier-articles__item {
    position: relative;
    width: 100%;
    margin-bottom: 4rem;
    transform: translateX(0);
    transition: .3s transform $ease-in-out;

    p {
      margin-bottom: 0;
    }

    @media screen and (min-width: $mq-tablet-m) {
      width: calc(50% - 1.5rem);
    }

    &:hover {
      transform: translateX(10px);
    }
  }

  .dossier-articles__item__excerpt {
    text-align: justify; 
  }

  .dossier-articles__item__author {
    margin-bottom: 2rem;
    font-family: $ff-bodytext;
    font-size: 1.6rem;
    letter-spacing: 0.05rem;

    b {
      font-weight: 600;
    }
  }
}

.section-last--publication {
  .title-7 {
    margin-bottom: 2rem;
  }

  .article__authors__item {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    line-height: 1.3;
  }
}