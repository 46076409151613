/*
 * Wordpress Blocks
**/

.wp-block-cover {
  position: relative;
  overflow: visible;
}

.wp-block-cover__inner-container {
  width: 100% !important;
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  
  p {
    margin-bottom: 0;
    font-family: $ff-title;
    font-size: 1.2rem;
    font-weight: lighter;
    letter-spacing: 0.1rem;
    color: $c-dark;
    text-align: right !important;
  }
}

.wp-block-button {
  margin-bottom: $spacing * 4;
}

[class*="cta"] .wp-block-button__link {
  line-height: 1.2;
}

/*
 * Custom classes
**/

.cancel-padding-b {
  padding-bottom: 0 !important;
}

.last-block-of-section {

  margin-bottom: $section-margin-mobile;

  @media screen and (min-width: $mq-desktop-m) {
    margin-bottom: $section-margin;
  }
}

.last-block-of-content {
  margin-bottom: $page-margin-mobile;

  @media screen and (min-width: $mq-desktop-s) {
    margin-bottom: $page-margin;
  }
}

.lh2 {
  line-height: 2;
}

.negative-margin-b {
  margin-bottom: -8rem;
}

.flex-order-last-on-desktop {
  @media screen and (min-width: $mq-tablet-l) {
    order: 100;
    margin-right: 0 !important;
    margin-left: 4rem;
  }
}

.min-height-100vh {
  @media screen and (min-width: $mq-desktop-s) {
    min-height: calc(100vh - 70rem);
  }
}



.dans-les-medias__btn-archives {
  @media screen and (max-width: $mq-tablet-l) {
    margin-bottom: 4rem;
  }
}

.medias__about.bodytext--heading {
  margin-bottom: 0;
}

.archives__ls {
  display: none;
}

.custom-paginationjs {
  display: flex;
  justify-content: center;
  font-family: $ff-title;
  font-size: 1.8rem;
  font-weight: 300;

  .active {
    font-weight: 600;
  }

  ul {
    display: flex;

    li {
      position: relative;
      min-width: 10px;

      &:after {
        content:'';
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        width: 0%;
        height: 4px;
        background: #000;
        transition: .3s width $ease-in-out;
      }

      &:hover {
        &:after {
          width: 100%;
        }
      }
    }

    li:not(:last-of-type) {
      margin-right: 1rem;
    }
  }
}

.article__fb-container {
  order: -1;
  width: 100%;
  
  @media screen and (min-width: $mq-tablet-m) {
    order: 1;
    width: auto;
  }
}

.fb-like {
  [title="J'aime"] {
    font-family: $ff-title;
    font-size: 2rem;
  }
}

// cancel block behaviour for p generated from wp the_content()
.sb-cpnt__three__el__author p, .article__authors p {
  display: inline;
}

em {
  font-weight: normal;
  font-style: italic;
}

.rounded-box {
  padding: 3rem 2rem;
  border-radius: 3rem;
  border-top-left-radius: 0;
  box-shadow: 0 0 10px rgba(0,0,0,.2);

  @media screen and (min-width: $mq-tablet-m) {
    padding: 4rem;
  }
}

.container--margin-reduced {
  padding-top: 4rem !important;
}