$section-three-blocks__item-margin-r: 3rem;

[class*="section-three-blocks--"] {
  position: relative;
  overflow: hidden;
  
  &[class*="--democraty"] {
    background: $c-starship;

    @media screen and (min-width: $mq-tablet-m) {
      &::after {
        content:'';
        position: absolute;
        z-index: 1;
        bottom: 0;
        right: 0;
        display: block;
        transform: translate(0%, 50%);
        width: 500px;
        height: 860px;
        background: url('../img/democraty-icon.svg') center/cover;
      }
    }

    @media screen and (min-width: $mq-desktop-l) {
      &:after {
        transform: translate(0%, 50%) scale(1.3);
      }
    }
  }

  &[class*="--mobilizations"] {
    background: $c-givry;
  }
}

.section-three-blocks__item {
  width: 100%;
  margin-bottom: 4rem;
  transition: width .5s $ease-in-out;

  &:hover {
    cursor: pointer;
  }

  &:nth-of-type(3) {
    display: none;

    @media screen and (min-width: $mq-tablet-l) {
      display: flex;
    }
  }

  @media screen and (min-width: $mq-tablet-m) {
    width: calc(100% / 2 - #{$section-three-blocks__item-margin-r} / 2);

    &:not(:first-of-type) {
      margin-left: #{$section-three-blocks__item-margin-r};
    }
  }

  @media screen and (min-width: $mq-tablet-l) {
    width: calc(100% / 3 - #{$section-three-blocks__item-margin-r} * 2 / 3);
    
  }

  @media screen and (min-width: $mq-desktop-s) {
    // width: calc(100% / 3 - 3rem);
    width: calc(100% / 3 - #{$section-three-blocks__item-margin-r});

    &:not(:first-of-type) {
      margin-left: calc(#{$section-three-blocks__item-margin-r} * 3 / 2);
    }
  }
}
