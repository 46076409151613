.our-pillars__item {
  display: flex;
  flex-flow: row wrap;

  @media screen and (min-width: $mq-desktop-s) {
    flex-wrap: nowrap;
  }
}

.our-pillars__item__img {
  width: 100%;
  height: calc((100vw - #{$wrapper-margin-mobile} * 2) / 16 * 9);
  margin-bottom: 3rem;

  @media screen and (min-width: $mq-tablet-m) {
    height: calc((100vw - #{$wrapper-margin-tablet} * 2) / 16 * 9);
    margin-bottom: 4rem;
  }

  @media screen and (min-width: $mq-desktop-s) {
    width: 30vw;
    height: 20vw;
    min-height: 350px;
    max-height: 400px;
    margin-right: 4rem;
    margin-bottom: 0;
  }
}

.our-pillars__item-outer:nth-of-type(odd) .our-pillars__item__img {
  @media screen and (min-width: $mq-desktop-s) {
    order: 1;
    margin-right: 0;
    margin-left: 4rem;
  }
}

.our-pillars__title {
  // text-align: center;

  // @media screen and (min-width: $mq-desktop-s) {
  //   text-align: left;
  // }
}

.our-pillars__item__content {
  @media screen and (min-width: $mq-desktop-s) {
    width: 60%;
  }
}