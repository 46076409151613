.sb-cpnt__pdf {
  margin-top: -1rem;
  margin-bottom: $sb-cpnt-spacing / 2;
  border-bottom: $border-style;
  padding-bottom: 2rem;
}

.sb-cpnt__pdf__link__text {
  margin-right: 1rem;
  margin-left: 1.5rem;
  margin-bottom: 0;
}