.header {
  transition: margin-bottom .3s $ease-in-out;

  @media screen and (min-width: $mq-tablet-s) {
  }
}

.menu {
  position: fixed;
  z-index: 100;
  top: 0;
  display: flex;
  width: 100%;
  height: $nav-menu-height;
  padding: 0rem 4rem;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  background: $c-light;
  transition: height .3s $ease-in-out;

  @media screen and (min-width: $mq-tablet-s) {
    height: $nav-menu-height-tablet;
  }

  &.is-hidden {
    height: 0 !important;
  }

  &.is-reduced {
    height: 60px;
  }
}

.hidden-input-search {
  position: absolute;
  z-index: -1;
  top: -150px;
  left: 6rem;
  padding: 1rem;
  padding-top: 2rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  background: $c-light;
  transform: translateY(50%);
  transition: top .3s $ease-in-out;
}

.menu-inner {
  width: 100%;
  background: $c-light;
}

.menu__el:first-of-type, .menu__el:last-of-type {
  // width: 8rem;
  [class*="menu__btn"]:first-of-type {
    margin-right: 1rem;
  }
}

.menu__btn--text {
  position: relative;
  align-self: center;
  font-family: $ff-title;
  font-size: 1.4rem;
  font-weight: 500;
  text-transform: uppercase;
  transition: font-size .3s $ease-in-out;

  &::after {
    content:'';
    position: absolute;
    display: block;
    width: 0%;
    height: 2px;
    background: $c-dark;
    transition: .3s width $ease-in-out;
  } 

  &:hover::after {
    width: 100%;
  }

  .menu.is-reduced & {
    font-size: 1.2rem;
  }

  .menu.is-hidden & {
    font-size: 0;
  }
}

.menu__btn__icon {
  width: 2rem;
  transition: width .3s $ease-in-out;

  @media screen and (min-width: $mq-tablet-m) {
    width: 2.5rem;
  }

  .menu.is-reduced & {
    width: 1.8rem;
  }

  .menu.is-hidden & {
    width: 0;
  }
}

.menu__el--logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.menu__el:last-of-type .menu__btn__icon{
  width: 1.8rem;

  @media screen and (min-width: $mq-tablet-m) {
    width: 2.3rem;
  }

  .menu.is-reduced & {
    width: 1.5rem;
  }

  .menu.is-hidden & {
    width: 0;
  }
}

.menu__logo {
  width: 5rem;
  transition: width .3s $ease-in-out;

  @media screen and (min-width: $mq-tablet-s) {
    margin-bottom: 0.5rem;
  }

  .menu.is-reduced & {
    width: 4rem;
  }

  .menu.is-hidden & {
    width: 0;
  }
}

.menu__baseline {
  display: none;
  transition: width .3s $ease-in-out;

  @media screen and (min-width: $mq-tablet-s) {
    display: block;
    width: 10rem;
  }

  .menu.is-reduced & {
    width: 0;
  }

  .menu.is-hidden & {
    width: 0;
  }
}

.menu__btn--burger {
  position: relative;
  z-index: 10000;
  font-size: 4rem;
  transition: font-size .3s $ease-in-out;

  .menu.is-reduced & {
    font-size: 3rem;
  }

  .menu.is-hidden & {
    font-size: 0;
  }
}

.menu-nav {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: -500px;
  width: 100%;
  max-width: 412px;
  height: 100vh;
  background: $c-dark;
  overflow: scroll;
  // background: $c-dark-transparent;

  @media screen and (min-width: $mq-desktop-s) {
    overflow: visible;
  }
}

.menu-nav__overlay {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  background: $c-dark;
  opacity: 0;
}

.menu-nav-inner {
  position: relative;
  z-index: 1000;
  padding: 3rem;
  padding-top: 9rem !important;
  transition: padding .3s $ease-in-out;

  @media screen and (min-width: $mq-tablet-m) {
    padding: 6.8rem;
  }
}

.menu-nav__close-btn {
  position: absolute;
  z-index: 1001;
  top: 12px;
  left: 87%;
  color: $c-light;
  font-size: 3.5rem;
  cursor: pointer;
}

[class*="menu-nav__item__headline-btn"] {
  @extend %text-uppercase;
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 1.5rem;
  color: $c-light;
  font-family: $ff-title;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
  text-align: left;
  transition: color .3s $ease-in-out;

  &:hover {
    color: $c-halfbaked;
  }
}

.menu-nav__item__headline-btn {
  &::after {
    content:'';
    position: absolute;
    top: 25%;
    right: 0;
    transform: translateX(0px) rotate(0);
    display: block;
    width: 10px;
    height: 15px;
    background: url('./../img/arrow-sm-btn-wit.svg') center/cover;
    transition: transform .3s $ease-in-out;
  }

  &:hover {
    &::after {
      transform: translateX(0) rotate(0);
    }
  }
}

.menu-nav__item {
  @media screen and (min-width: $mq-desktop-s) {
    position: relative;

    &:hover {
      .menu-nav__item__ls {
        display: block;
      }
    }
  }
}

.menu-nav__item__ls {
  @media screen and (min-width: $mq-desktop-s) {
    display: none;
    position: absolute;
    top: -30px;
    left: 344px;
    padding: 3rem 10rem 2rem;
    padding-left: 2rem;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    background: $c-dark;
  }
}

.menu-nav__item__el {
  display: none; 
  margin-bottom: 1.5rem;
  margin-left: 2rem;

  @media screen and (min-width: $mq-desktop-s) {
    display: block;
  }
}

.menu-nav__item__link {
  display: inline-block;
  color: $c-light;
  font-size: 1.8rem;
  font-weight: 300;
  letter-spacing: 0.2rem;

  &::after {
    content: '';
    transform: translateY(2px);
    display: block;
    width: 0%;
    height: 2px;
    background-color: transparent;
    transition: all .3s $ease-in-out;
  }

  &:hover {
    color: $c-light;

    &::after {
      display: block;
      width: 100%;
      background-color: $c-light;
    }
  }
}

.menu-nav__items-block {
  border-bottom: $border-style;
  border-color: $c-light;
  padding-bottom: 1.5rem;
  margin-bottom: 3rem;

  &:first-of-type {
    border-bottom-color: $c-halfbaked;
  }

  &:nth-of-type(2) {
    border-bottom-color: $c-apricot;
  }

  &:nth-of-type(3) {
    border-bottom-color: $c-starship;
  }

  &:last-of-type {
    margin-bottom: 8rem;
  }
}

.menu-nav__item__secondary-links {
  margin-bottom: 4rem;

  .menu-nav__item__link {
    @extend %text-uppercase;
    position: relative;
    display: inline-block;
    margin-bottom: 1.5rem;
    color: $c-light;
    font-size: 1.6rem;
    font-weight: 300;
    letter-spacing: 0.15rem;
  }
}

.menu-nav__item.is-active {
  @media screen and (max-width: $mq-desktop-s) {
    .menu-nav__item__headline-btn {
      &::after {
        transform: translateX(0) rotate(90deg);
      }
    }
  }
  
  @media screen and (min-width: $mq-desktop-s) {
    .menu-nav__item__ls {
      display: block; 
    }
  }
}
