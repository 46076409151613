.woocommerce {
  font-family: $ff-title !important;
}

.wc__content-container {
  margin-top: 10rem;
}

.wc__content-container.container--section {
  padding-top: 0;
  @media screen and (min-width: $mq-tablet-m) {
    padding-top: 4rem;
  }
}

$wc-nav-height: 70px;

.shop-nav,
.woocommerce-MyAccount-navigation {
  &.is-reduced ul {
    margin-top: 6rem;
  }

  &.is-on-top ul {
    margin-top: 0;
  }


  ul {
    position: fixed;
    z-index: 10;
    margin-top: $nav-menu-height;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100vw;
    padding: 1rem 2rem;
    background: $c-snuff;
    transition: margin-top .3s $ease-in-out;

    @media screen and (min-width: $mq-tablet-s) {
      margin-top: $nav-menu-height-tablet;
    }
  }

  .woocommerce-MyAccount-navigation ul {
    height: calc(#{$wc-nav-height} / 2 * 7);
  }

  .shop-nav ul {
    height: calc(#{$wc-nav-height} * 4);
  }

  .shop-nav__el {
    display: flex;
    justify-content: center;
  }

  li {
    width: 100%;
    font-family: $ff-title;
    font-size: 1.6rem;
    font-weight: bold;

    @media screen and (min-width: $mq-tablet-s) {
      width: auto;
      margin-right: 2rem;
    }
  }

  li a {
    display: inline-block;

    &::after {
      content: '';
      display: block;
      width: 0;
      height: 3px;
      background: $c-dark;
      transition: .3s width $ease-in-out;

    }

    &:hover {
      &::after {
        width: 100%;
      }
    }
  }
}

.woocommerce-MyAccount-content {
  margin-top: calc(#{$wc-nav-height} / 2  * 7 + #{$spacing} * 2);
  font-family: $ff-title;

  @media screen and (min-width: $mq-tablet-s) {
    margin-top: calc(#{$wc-nav-height} + #{$spacing} * 2);
  }
}

// account details
.woocommerce-EditAccountForm, .woocommerce-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .woocommerce-EditAccountForm-block-title, fieldset legend {
    width: 100%;
    margin-bottom: 1rem;
    font-size: 2rem;
    font-weight: lighter;
    text-transform: uppercase;
  }

  p.woocommerce-form-row {
    // flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and (min-width: $mq-tablet-m) {
      width: calc(50% - 2rem);
    }

    label {
      font-size: 1.5rem;
      font-weight: 500;
      text-transform: uppercase;
    }

    .woocommerce-Input {
      height: 60px;
      padding: 2rem;
      border: 2px solid $c-snuff;
    }
  }

  fieldset {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;

    p.woocommerce-form-row {
      width: 100%;

      @media screen and (min-width: $mq-tablet-m) {
        width: calc((100% / 3) - 2rem);
      }
    }
  }

  .woocommerce-form-row {
    text-align: left;

    span {
      margin-top: 1rem;
      font-size: 1.2rem;
      line-height: 1.4;
    }
  }
  

  .woocommerce-Button[type="submit"] {
    font-size: 1.4rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    padding: 1rem 2rem;
    border: 3px solid $black;
    transition: all .3s $ease-in-out;

    &:hover {
      color: $c-light;
      background: $black;
    }
  }

  .clear {
    display: none;
  }
}

.woocommerce-notices-wrapper {
  margin: 0;
  margin-bottom: 4rem;
  color: $c-linkedin;
}

.woocommerce-Addresses {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.woocommerce-Address {
  width: 100%;
  margin-bottom: 2rem;

  @media screen and (min-width: $mq-tablet-m) {
    width: calc(50% - 2rem);
    margin-bottom: 0;
  }

  .woocommerce-Address-title {
    margin-bottom: 1rem;
  }

  address {
    position: relative;
    padding: 3rem;
    border: 3px solid $c-snuff;
    border-radius: 3rem;
    border-top-left-radius: 0;
  }

  &:first-of-type address {
    border-top-left-radius: 3rem;
    border-bottom-right-radius: 0;
  }

  address a.edit {
    position: absolute;
    top: 2rem;
    right: 2rem;

    &::after {
      content:'';
      display: block;
      width: 20px;
      height: 20px;
      background: url('./../img/edit-icon.svg') center/cover;
    }
  }
}

.wc-button {
  font-family: $ff-title;
  font-size: 1.4rem;
  font-weight: 600;
  // letter-spacing: .05rem;
  text-transform: uppercase;
  padding: 1rem 2rem;
  border: 3px solid $black;
  border-radius: 1rem;
  border-top-left-radius: 0;
  transition: all .3s $ease-in-out;

  &:hover {
    color: $c-light;
    background: $black;
  }
}

.woocommerce-MyAccount-content {
  & .button:not(.delete) {
    @extend .wc-button;
  }

  .button.default {
    border: 0;
    background: transparent;
    
    &:hover {
      color: #000;
      background: transparent;
    }
  }

  .button.delete {
    position: relative;
    // color: transparent;
    font-size: 0;

    &::after {
      position: absolute;
      left: 0; top: -10px;
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      background: url('./../img/close-icon.svg') center/cover;
    }
  }
}

.woocommerce-MyAccount-paymentMethods {
  margin-bottom: 4rem;
}

table.account-payment-methods-table {
  font-size: 1.4rem;
}

th.woocommerce-PaymentMethod, 
td.woocommerce-PaymentMethod {
  padding-right: 2rem;
}

.ca-wc-memberships-restriction-message {
  padding-top: 1rem;
  color: #F5424C;
  font-family: $ff-alt;
  font-size: 1.6rem;
}

.ca-wc-memberships-restriction-message__link {
  position: relative;
  font-weight: bold;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -.5rem;
    display: block;
    width: 0%;
    height: 2px;
    background: #F5424C;
    transition: width .3s $ease-in-out;
  }

  &:hover::after {
    width: 100%;
  }
}

.les-abonnements {
  width: 100%;
}

.les-abonnements__item {
  width: 100%;
  margin-bottom: 3rem;
  box-shadow: 0 0 7px rgba(0,0,0,.1);
  border-radius: 2rem;
  border-top-left-radius: 0;

  @media screen and (min-width: $mq-tablet-l) {
    width: calc(50% - 1.5rem);
  }

  p {
    display: none;
  }

  .les-abonnements__item__title {
    padding: 3rem 2rem;
  }

  .les-abonnements__item__content {
    padding: 2rem;
  }

  .les-abonnements__item__title {
    border-top-right-radius: 2rem;

    @media screen and (min-width: $mq-tablet-m) {
      font-size: 1.8rem;
    }
  }

  &:first-of-type .les-abonnements__item__title {
    background: $c-starship;
  }

  &:last-of-type .les-abonnements__item__title {
    background: $c-apricot;
  }
}

.les-abonnements__item__perks {
  padding: 2rem 3rem;
  font-family: $ff-title;
  font-size: 1.6rem;

  li {
    margin-bottom: 1rem;
  }

  li.inactive {
    color: $c-snuff;
  }
}

.les-abonnements__item__choice {
  width: calc(50% - 1rem);
  border-radius: 1rem;
  background: $c-snuff;
  position: relative;
  bottom: 0;
  transition: bottom .3s $ease-in-out;

  &:hover {
    bottom: 1rem;
  }

  .les-abonnements__item__choice__title {
    margin: 0;
    padding: .5rem 1rem;
    border-top-right-radius: 1rem;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.05rem;
    color: $white;
  }

  &:first-of-type > .les-abonnements__item__choice__title {
    background: $c-halfbaked;
  }

  &:last-of-type > .les-abonnements__item__choice__title {
    background: $c-apricot;
  }

  .les-abonnements__item__choice__description {
    padding: 1rem 2rem;
    font-family: $ff-alt;
    font-size: 1.2rem;
    line-height: 1.3;

    b {
      font-family: $ff-title;
      font-size: 1.6rem;
    }
  }
}

.les-abonnements__asterisks {
  p {
    font-family: $ff-alt;
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }
}

.les-revues ul.products {
  display: flex;
  flex-wrap: wrap;
  // justify-content: center;
  // justify-content: space-between;
  
  .product {
    // flex-grow: 1;
    // width: calc(50% - 2rem);
    position: relative;
    margin-right: 3rem;
    margin-bottom: 3rem;
    border-top-right-radius: 2rem;
    box-shadow: 0 0 10px rgba(0,0,0,.1);
    background: $c-light;
    transition: all .3s $ease-in-out;
  
    &:hover {
      transform: translateY(-1rem);
    }
  
    @media screen and (max-width: $mq-mobile-l) {
      width: 100%;
      
      img {
        width: calc(100% - 4rem);;
        height: auto;
      }
    }
  
    @media screen and (min-width: $mq-tablet-m) {
      flex-grow: 0;
      // width: calc(25% - 2rem);
    }
  
    @media screen and (min-width: $mq-desktop-m) {
      // width: calc(20% - 2rem);
    }
  
    a:first-of-type {
      // padding: 2rem;
    }
  }

  .attachment-woocommerce_thumbnail {
    margin: 2rem;
  }
  
  .woocommerce-loop-product__title {
    margin-bottom: 0;
    padding: 1rem 2rem;
    padding-right: 1rem;
    font-size: 1.6rem;
    background: $c-snuff;
  }
}

.woocommerce-orders-table {
  &__row {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    padding: 2rem;
    border: $border-style;
    border-radius: 2rem;
    border-top-right-radius: 0;
    box-shadow: 0 0 7px rgba(0,0,0,.1);
    font-family: $ff-title;
    font-size: 1.4rem;

    @media screen and (min-width: $mq-tablet-m) {
      flex-wrap: nowrap;
    }
  }

  &__cell-order-number {
    width: 100%;

    @media screen and (max-width: $mq-tablet-m) {
      padding-bottom: 1rem;
    }
  }

  &__cell:not(.woocommerce-orders-table__cell-order-actions) {
    padding-right: 2rem;

    @media screen and (min-width: $mq-tablet-m) {
      width: 100%;
    }
  }

  &__cell__title {
    font-weight: 600;
  }
  
  .woocommerce-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    border: 0 !important;
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 0 !important;
  }
}

// single-product page
.product {
  @media screen and (min-width: $mq-tablet-l) {
    display: flex;

    .woocommerce-product-gallery {
      // width: 30%;
      margin-right: 2rem;
    }
    
    .wp-post-image {
      // width: 100%;
    }
  }
}

// single-product page -- cover + sommaire
.ca__wc__product__cover {
  width: calc(100vw - 4rem);
  height: calc((100vw - 4rem) * .75);
  flex-shrink: 0;
  margin-bottom: 3rem;

  @media screen and (min-width: $mq-tablet-m) {
    width: calc(100vw - 12rem);
  height: calc((100vw - 12rem) * .75);
  }

  @media screen and (min-width: $mq-tablet-l) {
    width: 408px;
    height: 300px;
    margin-right: 4rem;
    margin-bottom: 0;
  }

  .ca__wc__product__cover__link {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.single_variation_wrap {
  @media screen and (max-width: $mq-mobile-s) {
    width: 100%;
  }
}

.woocommerce-product-gallery__wrapper {
  margin: 0;
}

.woocommerce-product-gallery__image {
  display: flex;
  justify-content: center;
  margin-bottom: 4rem;
}

.ca-wc-single-product-title-container {
  margin-bottom: 2rem;
}

.woocommerce-product-details__short-description {
  @extend [class*="bodytext"];
  margin-bottom: 4rem;
}

.variations_form.cart {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  @media screen and (min-width: $mq-tablet-m) {
    justify-content: space-between;
  }

  .variations {
    width: 100%;

    select {
      width: 100%;
      height: 50px;
      margin-bottom: .5rem;
      padding-left: 1rem;
      font-family: $ff-title;
      font-weight: bold;
      letter-spacing: 0.1rem;
      text-transform: uppercase;
    }

    @media screen and (min-width: $mq-tablet-s) {
      width: 250px;
    }
  }
}

.single_add_to_cart_button {
  margin-left: auto;
}

.variations_form {
  table.variations {}
  td.value select {}
  td.value option {}

}

.woocommerce-message[role="alert"] {
  font-family: $ff-title;
  font-size: 1.4rem;
  color: #F5424C;

  & > a.wc-forward,
  & > a.restore-item {
    border: 1px solid $c-dark;
    padding: .2rem 1rem;
    margin: 0 1rem;
    font-family: $ff-title;
    text-transform: uppercase;
    color: $c-dark;
  }
}

// return to shop
a.wc-backward,
.single_add_to_cart_button, 
.wc-proceed-to-checkout > a.checkout-button {
  @extend [class*="cta"], [class*="cta--"][class*="--wit"];
}

.woocommerce .wc-memberships-message .wc-memberships-restriction-message {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
}

.woocommerce-cart-form {
  padding: 2rem;
  border: 3px solid #000;
  border-radius: 1rem;
  margin-bottom: 4rem;
  border-top-left-radius: 0;
  font-family: $ff-title;
  font-size: 1.4rem;

  @media screen and (min-width: $mq-tablet-m) {
    font-size: 1.6rem;
  }

  table.cart {
    width: 100%;

    th {
      padding: 1rem;
      padding-left: 0;
    }
  }

  // .product-thumbnail {
  //   display: none;
  // }

  td.product-name {
    font-size: 1.2rem;
    // width: 100px;

    @media screen and (min-width: $mq-tablet-m) {
      font-size: 1.6rem;
    }
  }

  .product-price,
  .product-subtotal {
    width: 75px;
  }

  .product-quantity {
    width: 75px;

    input { width: calc(100% - 1rem); }
  }
}

.cart-collaterals {
  font-family: $ff-title;
  font-size: 1.4rem;

  @media screen and (min-width: $mq-tablet-m) {
    font-size: 1.6rem;
  }

  .shop_table {
    margin-bottom: 2rem;

    th {
      padding-right: 1rem;
      vertical-align: top;
    }

    ul {
      margin-bottom: 0;
    }

    p {
      font-size: 1.2rem;
      margin-bottom: .5rem;
    }

    .woocommerce-shipping-calculator {
      margin-bottom: 1rem;
    }

    .shipping-calculator-button {
      padding: 0.5rem;
      border: 1px solid #000;
      font-size: 1.2rem;
      font-weight: 500;
      text-transform: uppercase;
    }

    .shipping-calculator-form {
      padding: 1rem 0;
    }
  }

  h2 {
    @extend .title-7;
    margin-bottom: 2rem;
  }
}

.woocommerce-variation-add-to-cart {
  display: flex;
}

.quantity > input {
  font-family: $ff-title;
  font-weight: bold;
  font-size: 1.6rem;
  width: 50px;
  margin-right: 1rem !important;
  height: 50px;
  text-align: center;
}

.woocommerce-cart-form__cart-item {
  .quantity > input {
    height: auto;
    font-size: 1.2rem;
  }
}

.woocommerce-form-register {
  font-size: 1.4rem;

  .mailchimp-newsletter {
    width: 100%;
  }

  .woocommerce-privacy-policy-text {
    font-size: 1.2rem;
  }

  p {
    width: 100%;
  }
}

.woocommerce-form-login {
  font-size: 1.4rem;
}

.woocommerce-checkout {
  font-family: $ff-title !important;

  p.woocommerce-form-row {
    // flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and (min-width: $mq-tablet-m) {
      width: calc(50% - 2rem);
    }
  }

  label {
    width: 100%;
    font-size: 1.5rem;
    font-weight: 500;
    text-transform: uppercase;
  }

  input {
    width: 100%;
    height: 60px;
    padding: 2rem;
    border: 2px solid $c-snuff;
  }

  .woocommerce-input-wrapper {
    margin: 0;
  }
}

.woocommerce-billing-fields__field-wrapper,
.woocommerce-shipping-fields__field-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0;
}

.form-row {
  width: 100%;

  @media screen and (min-width: $mq-tablet-m) {
    width: calc(50% - 1rem);
  }
}

.woocommerce-form__label-for-checkbox,
.mailchimp-newsletter {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.woocommerce-form__input-checkbox {
  width: auto !important;
  height: auto !important;
  margin-right: 1rem;
  margin-top: .5rem;
}

.woocommerce-checkout-review-order-table {
  width: 100%;
  margin-bottom: 4rem;
}

.woocommerce-checkout-payment {
  font-size: 1.6rem;
}

.wc_payment_methods.payment_methods {
  margin-bottom: 3rem;
}

.woocommerce-terms-and-conditions-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  font-size: 1.2rem;

  .form-row,
  .woocommerce-form__label-for-checkbox {
    width: 100%;
    flex-grow: 1;
  }
}

.form-row.place-order {
  width: 100%;
}

.woocommerce-additional-fields__field-wrapper {
  margin: 0;

  .input-text  {
    width: 100%;
  }

  .form-row.notes {
    width: 100%;
  }
}

.form-row.place-order .button {
  padding: 0.5rem 1rem;
  border: 2px solid $c-dark;
  text-transform: uppercase;
  font-size: 1.3rem;
  color: $c-dark;
  transition: all .3s $ease-in-out;

  &:hover {
    color: $c-light;
    background: $c-dark;
  }
}

.lost_reset_password {
  font-family: $ff-title;
  font-size: 1.6rem;

  label {
    font-weight: bold;
  }

  .button {
    padding: 0.5rem 1rem;
    border: 2px solid $c-dark;
    text-transform: uppercase;
    font-size: 1.3rem;
    color: $c-dark;
    transition: all .3s $ease-in-out;

    &:hover {
      color: $c-light;
      background: $c-dark;
    }
  }
}

#customer_login {
  .woocommerce-form-row + .woocommerce-form-row + .form-row {
    // order:10;
  }

  button.woocommerce-form-login__submit {
    font-size: 1.4rem;
    letter-spacing: .1rem;
    text-transform: uppercase;
    padding: 1rem 2rem;
    border: 3px solid $c-dark;
    color: $c-dark;
    transition: all .3s $ease-in-out;
  
    &:hover {
      color: $c-light;
      background: $c-dark;
    }
  }
  
  .woocommerce-LostPassword.lost_password {
    @media screen and (max-width: $mq-tablet-m) {
      width: 100%;
    }
  }
}
