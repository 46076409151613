// title of articles
.article {
  padding-bottom: $article-mb;

  p {
    @extend [class*="bodytext"];
    margin-bottom: $article_p-margin-b;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: $ff-title;
    line-height: 1.2;
  }

  h1, .h1 {
    @extend .title-1;
    line-height: 1;
  }

  h2, .h2 {
    @extend .title-2;
  }

  h3, .h3 {
    @extend .title-3;
    font-size: 2.4rem;
    letter-spacing: 0.1rem;
    margin-bottom: $article_titles-margin-b;
  }

  h4, .h4 {
    @extend .title-3;
    font-size: 2rem !important;
    letter-spacing: 0.1rem !important;
    margin-bottom: $article_titles-margin-b;
  }

  p + h1, p + h2, p + h3, p + h4, p + h5, p + h6 {
    padding-top: $article_chapter-margin-b;
  }

  > p:last-of-type {
    margin-bottom: $article_heading-margin-b;
  }

  .article__couverture,
  .wp-block-cover {
    min-height: auto;
    width: 100%; 
    height:$article_cover-height-mobile;
    margin-bottom: $article_cover-margin-b;

    @media screen and (min-width: $mq-tablet-m) {
      height: $article_cover-height;
    }
  }
  
  .article__heading {
    margin-bottom: $article_heading-margin-b;
  }

  [class*="article__press-btn"] {
    position: relative;
    display: inline-block;
    text-decoration: none;
    font-family: $ff-title;
    font-size: 1.6rem;
    font-weight: 500;
    color: $c-dark;

    &:first-of-type {
      margin-right: 4rem;
      margin-bottom: 4rem;

      @media screen and (min-width: $mq-tablet-s) {
        margin-bottom: 0;
      }
    }

    &::before {
      content:'';
      position: absolute;
      top: 4rem;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      height: 3px;
      width: 0%;
      background: $c-dark;
      transition: .3s width $ease-in-out;
    }

    &:hover::before {
      width: 100%;
    }

    & svg { height: 15px; }
  }

  .article__notes {
    border-top: $border-style;
    padding-top: $margin-between-article-and-notes;
    font-family: $ff-title;
    font-size: 1.8rem;
    font-weight: 300;

    li {
      margin-bottom: $article_notes-margin-b;
    }

    b {
      font-weight: 800;
    }

    p {
      margin-bottom: 1rem;
      font-family: $ff-title;
      font-size: 1.5rem;
      font-weight: 300;
      
      @media screen and (min-width: $mq-tablet-m) {
        margin-bottom: 2rem;
        font-size: 1.8rem;
      }
    }
  }

  a /* general links in article */{
    text-decoration: underline;
  }

  [class*="cta"] {
    text-decoration: none;
  }

  a.note /* lien ancre */ {
    font-weight: 800;
    color: $c-facebook;
    text-decoration: none;
  }

  .article__notes__el > a {
    text-decoration: none;
  } 
}

.article__content--epingle {
  @media screen and (min-width: $mq-tablet-s) {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  @media screen and (min-width: $mq-tablet-l) {
    display: block;
  }

  @media screen and (min-width: $mq-desktop-s) {
    display: flex;
  }

  img {
    width: 100%;
    margin-bottom: 2rem;

    @media screen and (min-width: $mq-tablet-s) {
      width: 200px;
      flex-shrink: 0;
      margin-right: 2rem;

    }

    @media screen and (min-width: $mq-tablet-l) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 2rem;
    }

    @media screen and (min-width: $mq-desktop-s) {
      width: 200px;
      margin-right: 2rem;
      margin-bottom: 0;
    }
  }

  .article__content__text {
    flex-grow: 1;
  }
}
