$echo_item_margin-b: 12rem;
$echo_spacing-block: 4rem;
$echo_spacing-img-mobile: 2rem;
$echo_spacing-img: $echo_spacing-img-mobile * 2;
$echo_gallery-min_height: 360px;

.echo-outer-2  {
  position: relative;
  &::before {
      content: '';
      position: absolute;
      // z-index: -1;
      top: 0;
      left: 0;
      transform: translateY(-2.7rem);
      display: block;
      width: 80px;
      height: 120px;
      background: url('../img/guillemet.svg') center/cover;
    }
}

.echo-outer {
  background: $c-snuff;

  &.container {
    padding-top: $spacing * 3;

    @media screen and (min-width: $mq-tablet-m) {
      padding-top: $spacing * 6;
    }
  }
}

.echo__el {
  margin-bottom: $echo_item_margin-b;

  @media screen and (min-width: $mq-tablet-m) {
    margin-bottom: $echo_item_margin-b;
  }

  
  &:nth-child(even) {
    @media screen and (min-width: $mq-tablet-m) {
      flex-direction: row-reverse;
    }
  }
}

.echo__title {
  margin-bottom: 6rem;

  @media screen and (min-width: $mq-tablet-m) {
    margin-bottom: 10rem;
  }
}

[class*="echo__content"], 
.echo__images {
  width: 100%;
  
  @media screen and (min-width: $mq-tablet-l) {
    width: calc(50% - #{$echo_spacing-block});
  }
}

[class*="echo__content"] {
  margin-bottom: $spacing * 2;
}

.echo__images {
  min-height: $echo_gallery-min_height;
}

.echo__images__item {
  display: block;
  width: 50%;
  height: calc(50% - #{$echo_spacing-img-mobile} / 2);

  @media screen and (min-width: $mq-tablet-m) {
    height: calc(50% - #{$echo_spacing-img} / 2);
  }

  &:first-of-type {
    // image 1 => largeur - la marge en bas
    margin-bottom: #{$echo_spacing-img-mobile};

    @media screen and (min-width: $mq-tablet-m) {
      margin-bottom: #{$echo_spacing-img};
    }
  }

  &:last-of-type {
    // image 1 (grande vertical)
    // => largeur - la marge à gauche
    width: calc(50% - #{$echo_spacing-img-mobile});
    height: 100%;
    margin-left: #{$echo_spacing-img-mobile};

    @media screen and (min-width: $mq-tablet-m) {
      width: calc(50% - #{$echo_spacing-img});
      margin-left: #{$echo_spacing-img};
    }
  }
}

.echo__el:nth-child(even) {
  .echo__images__item:last-of-type {
    // Inverser l'ordre 
    // et inverser les marges
    order: -1;
    margin-left: 0;
    margin-right: #{$echo_spacing-img-mobile};

    @media screen and (min-width: $mq-tablet-m) {
      width: calc(50% - #{$echo_spacing-img});
      margin-right: #{$echo_spacing-img};
    }
  }
}

.echo__text {
  position: relative;

  p {
    position: relative;
    z-index: 1;
  }

  p:first-child {
    
  }
}


