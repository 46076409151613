.container {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  font-weight: normal;
  padding-top: $nav-menu-height + $spacing * 2;

  @media screen and (min-width: $mq-tablet-m) {
    padding-top: $nav-menu-height-tablet + $spacing * 3;
  }

  @media screen and (max-width: $mq-tablet-l) {
    flex-wrap: wrap;
  }
}

[class*="container--"][class*="--echos"] {
  padding-top: calc(#{$nav-menu-height} + #{$spacing} * 2);

}

[class*="container--"][class*="--first"] {
  @extend .container;
  padding-top: $nav-menu-height + $spacing * 3;

  @media screen and (min-width: $mq-tablet-m) {
    padding-top: $nav-menu-height-tablet + $spacing * 3;
  }

    @media screen and (min-width: $mq-desktop-s) {
      padding-top: $nav-menu-height + 18rem;
    }
}

[class*="container--"][class*="--margin-minimized"] {
  @extend .container;
  padding-top: $section-margin-mobile / 2;
  padding-bottom: $section-margin-mobile / 2;

  @media screen and (min-width: $mq-tablet-m) {
    padding-top: $section-margin / 2;
    padding-bottom: $section-margin / 2;
  }
}

[class*="container--"][class*="--section"] {
  @extend .container;
  padding-top: $section-margin-mobile;
  padding-bottom: $section-margin-mobile;

  @media screen and (min-width: $mq-tablet-m) {
    padding-top: $section-margin;
    padding-bottom: $section-margin;
  }

  // Gestion du bg pour le composant "sexction-three-commitments"
  &[class*="--halfbaked"] {
    background: $c-halfbaked;
    overflow:hidden;
  }

  > .bg--halfbaked {
    @media screen and (min-width: $mq-tablet-m) {
      &::after {
        content:'';
        position: absolute;
        z-index: 1;
        bottom: 0;
        right: 0;
        display: block;
        transform: translate(40%, 20%);
        width: 700px;
        height: 860px;
        background: url('../img/ecology-icon.svg') center/cover;
      }
    }
  }

  &[class*="--apricot"] {
    background: $c-apricot;
    overflow:hidden;
  }

  > .bg--apricot {
    @media screen and (min-width: $mq-tablet-m) {
      &::after {
        content:'';
        position: absolute;
        z-index: 1;
        bottom: 0;
        right: 0;
        display: block;
        transform: translate(40%, 20%);
        width: 860px;
        height: 860px;
        background: url('../img/interculturality-icon.svg') center/cover;
      }
    }
  }
}

[class*="container--"][class*="--invert"] {
  .main {
    @media screen and (min-width: $mq-tablet-l) {
      order: 1;
    }
  }

  .sidebar {
    @media screen and (min-width: $mq-tablet-l) {
      margin-right: 6rem;
      margin-left: 0;
    }
  }
}

.container--section + .container--section {
  padding-top: 0 !important;
}

.sidebar, [class*="sidebar--"] {
  flex-basis: 50%;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  
  @media screen and (min-width: $mq-tablet-l) {
    flex-grow: 0;
    width: $sidebar-width;
    max-width: $sidebar-width;
    margin-left: 6rem;
  }

  @media screen and (min-width: $mq-desktop-s) {
    margin-left: 10rem;
  }
}

.sidebar--not-on-mobile {
  @media screen and (max-width: $mq-tablet-l) {
    display: none;
  }
}

.main {
  position: relative;
  width: 100%;

  // @media screen and (min-width: $mq-desktop-s) {
  //   min-height: calc(100vh - 70rem);
  // }
}

.opening {
  position: relative;
  z-index: 100000;
  height: 100vh;
  background: url('../img/bg-frontpage-mobile.png') center/cover;
  margin-bottom: -7rem;
  color: $c-light;
  text-align: center;

  @media screen and (min-width: $mq-tablet-s) {
    background: url('../img/bg-frontpage.png') center/cover;
  }
}

// .opening-bg {}

.opening__inner {
  max-width: 800px;
}

.opening__logo {
  margin-bottom: 2rem;

  svg {
    width: 130px;
    height: 140px;

    @media screen and (min-width: $mq-tablet-l) {
      width: 300px;
      height: 315px;
    }
  }
}

.opening__language-item {
  padding: 0 2rem;
}

.opening__subtitle {
  margin-bottom: 2rem;
  font-size: 2.1rem;
  font-weight: 500;
  letter-spacing: 0.2rem;

  @media screen and (min-width: $mq-tablet-l) {
    margin-bottom: 3rem;
    font-size: 3.4rem;
  }
}

.opening__text {
  font-family: $ff-title;
  font-size: 1.5rem;
  font-weight: 200;
  letter-spacing: 0.1rem;

  @media screen and (min-width: $mq-tablet-l) {
    font-size: 2rem;
    padding: 0;
  }
}

.opening__scrolldown-icon {
  // position: absolute;
  width: 50px;
  margin-top: 5rem;
  bottom: 5rem;
  left: 50%;
  // transform: translateX(-50%);
}

.form-container {
  display: none;
  position: fixed;
  z-index: 1000001;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100vw - 3rem);
  padding: 2.5rem;
  padding-top: 6rem;
  border-radius: 3rem;
  border-top-left-radius: 0;
  max-width: 1200px;
  background: $c-light;
  overflow: scroll;
  opacity:0;
  transition: .3s all $ease-in-out;

  &.is-active {
    display: block;
    opacity: 1;
  }

  label {
    display: none;
  }

  input {
    margin-bottom: 1rem !important;
  }

  @media screen and (min-width: $mq-tablet-m) {
    padding: 10rem 15rem;
    
    label {
      display: block;
    }

    input {
      margin-bottom: 3rem !important;
    }
  }

  &::before {
    content: 'inscription';
    position: absolute;
    top: 2rem;
    left: 2.5rem;
    font-family: $ff-title;
    font-size: 1.6rem;
    font-weight: 300;
    text-transform: uppercase;

    @media screen and (min-width: $mq-tablet-m) {
      font-size: 2rem;
    }
  }
}

.form-container__submit-btn {
  margin-left: auto;
  // text-align: right;
}

.form-container__overlay {
  position: fixed;
  // z-index: 1000000;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: $c-dark;
  // opacity: .5;
  opacity: 0;
  transition: .3s all $ease-in-out;
}

.form-container__input-container {
  width: 100%;

  @media screen and (min-width: $mq-tablet-m) {
    width: calc(50% - 2rem);
  }
}
