.staff__heading {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4rem;

  @media screen and (min-width: $mq-desktop-s) {
    margin-bottom: 8rem;
  }

  .staff__heading__about,
  .staff__heading__photo {
    width: 100%;
  }

  .staff__heading__about {
    @media screen and (min-width: $mq-tablet-l) {
      width: 1%;
      flex-grow: 1;
    }
  }

  .staff__heading__about {
    text-align: center;
    
    @media screen and (min-width: $mq-tablet-l) {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      text-align: left;
    }

    p {
      margin-bottom: 0;
    }
  }

  .staff__heading__photo {
    width: calc(100vw - #{$wrapper-margin-mobile});
    height: calc((100vw - #{$wrapper-margin-mobile}) / 2);
    margin-bottom: 2rem;

    @media screen and (min-width: $mq-tablet-s) {
      margin-bottom: 4rem;
    }

    @media screen and (min-width: $mq-tablet-l) {
      width: 60vw;
      max-width: 700px;
      height: 30vw;
      max-height: 400px;
      margin-right: 4rem;
      margin-bottom: 0;
    }
  }
}

.staff__members {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.staff__members__item {
  width: 100%;
  margin-bottom: 3rem;

  @media screen and (min-width: $mq-tablet-m) {
    width: calc(50% - 4rem);
    margin-bottom: 5rem;
  }
}

.staff__members__item__name {
  margin-bottom: 2rem;
  font-size: 2rem;
  font-weight: 600;
  
  @media screen and (min-width: $mq-tablet-m) {
    font-size: 2.4rem;

     br {
       display: none;
     }
  }
}

.staff__members__item__role {
  font-weight: 400;
}

.staff__members__item__about {
  font-size: 1.8rem;
}


