

.sb-cpnt {
  margin-bottom: $sb-cpnt-spacing;
  font-weight: normal;

  &--border-bottom {
    margin-bottom: $sb-cpnt-spacing / 3 * 2;
    border-bottom: $border-style;
    padding-bottom: $sb-cpnt-spacing / 3 * 2;
  }

  &--buy-magazine {
    border-bottom-left-radius: $sb-cpnt-border-radius;
    border-top-left-radius: 0;

    .sb-cpnt__title {
      border-top-left-radius: 0;
      background: $c-starship;
      color: $c-dark;
    }

    .sb-cpnt__content {
      border-bottom-left-radius: $sb-cpnt-border-radius;
    }

    .sb-cpnt__content-inner {
      margin-bottom: 4rem;
    }

    .sb-cpnt__cover, .sb-cpnt__price {
      width: 50%;
    }

    .sb-cpnt__cover {
      height: 100%;
    }

    .sb-cpnt__price {
      font-family: $ff-title;
      font-weight: 100;
      text-align: center;

      > div {
        line-height: 1;
      }

      > div:first-of-type {
        font-size: 8rem;
        margin-bottom: 0;
      }

      > div:last-of-type {
        font-size: 4rem;
      }
    }
  }
}

.sb-cpnt__title {
  display: block;
  margin-bottom: 0;
  padding: $sb-cpnt-border-radius;
  border-top-right-radius: $sb-cpnt-border-radius;
  border-top-left-radius: $sb-cpnt-border-radius;
  color: $c-light;
  background: $c-dark;
}
