.heading-block, [class*="heading-block--"] {
  position: relative;
  display: flex;
  align-items: center;
  // min-height: 350px;
  margin-top: #{$nav-menu-height};
  // padding: 10rem 0;
  overflow: hidden;

  @media screen and (min-width: $mq-tablet-m) {
    margin-top: #{$nav-menu-height-tablet};
  }

  @media screen and (min-width: $mq-desktop-s) {
    // min-height: 550px;
  }
}

.heading-block,
.heading-block--std {
  color: $c-light;
}

.heading-block--std {
  min-height: 250px;
  margin-top: calc(#{$nav-menu-height});

  @media screen and (min-width: $mq-desktop-s) {
    min-height: 450px;
  }
}

[class*="heading-block--"][class*="--democraty"],
[class*="heading-block--"][class*="--ecology"],
[class*="heading-block--"][class*="--interculturality"] {
  min-height: 350px;
  padding: 5rem 0;

  @media screen and (min-width: $mq-tablet-l) {
  padding: 8rem 0;
    
  }

  .wrapper {
    width: 100%;
  }

  .heading-block__title--std {
    margin-bottom: 1rem;
    
    @media screen and (min-width: $mq-tablet-m) {
      margin-bottom: 3rem;
    }
  }

  .heading-block__bodytext {
    width: 100%;

    @media screen and (min-width: $mq-tablet-s) {
      width: 60%;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &::after {
    @media screen and (min-width: $mq-tablet-s) {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      transition: all .5s $ease-in-out;
    }
  }

  p:last-of-type {
    margin-bottom: 0;
  }
}

[class*="heading-block--"][class*="--ecology"] {
  background: $c-halfbaked;

  &::after {
    @media screen and (min-width: $mq-tablet-s) {
      transform: translate(45%, -40%) scale(.8);
      height: 737px;
      width: 600px;
      background: url('../img/ecology-icon.svg') center/cover;
    }

    @media screen and (min-width: $mq-tablet-l) {
      transform: translate(25%, -35%) scale(1);
    }

    @media screen and (min-width: $mq-desktop-m) {
      transform: translate(-10%, -45%) scale(1.1);
    }

    @media screen and (min-width: $mq-desktop-l) {
      right:10%;
      transform: translate(10%, -45%) scale(1.1);
    }
  }
}

[class*="heading-block--"][class*="--democraty"] {
  background: $c-starship;

  &::after {
    @media screen and (min-width: $mq-tablet-s) {
      transform: translate(50%, -30%) scale(1);
      height: 700px;
      width: 405px;
      background: url('../img/democraty-icon.svg') center/cover;
    }

    @media screen and (min-width: $mq-tablet-l) {
      transform: translate(25%, -30%) scale(1.2);
    }

    @media screen and (min-width: $mq-desktop-s) {
      transform: translate(-5%, -30%) scale(1.4);
    }
    
    @media screen and (min-width: $mq-desktop-m) {
      right: 5%;
      transform: translate(0%, -30%) scale(1.6);
    }

    @media screen and (min-width: $mq-desktop-l) {
      right: 10%;
      transform: translate(0%, -30%) scale(1.8);
    }
  }
}

[class*="heading-block--"][class*="--interculturality"] {
  background: $c-apricot;

  &::after {
    @media screen and (min-width: $mq-tablet-s) {
      transform: translate(40%, -30%) scale(.6);
      height: 700px;
      width: 700px;
      background: url('../img/interculturality-icon.svg') center/cover;
    }

    @media screen and (min-width: $mq-tablet-m) {
      transform: translate(40%, -30%) scale(.8);
    }

    @media screen and (min-width: $mq-tablet-l) {
      transform: translate(35%, -40%) scale(.9);
    }

    @media screen and (min-width: $mq-desktop-s) {
      transform: translate(20%, -45%) scale(1.1);
    }
    
    @media screen and (min-width: $mq-desktop-m) {
      transform: translate(10%, -45%) scale(1.1);
    }
  }
}

.heading-block__content {
  display: flex;
  flex-wrap: wrap;
  // margin: initial;
}

.heading-block__title {
  width: 100%;
  margin-bottom: 1.5rem;
  
  &--std {
    margin-bottom: 0;
    text-transform: lowercase;
  }
}

.heading-block__bg {
  position: absolute;
  z-index: -1;
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
  /* Add the blur effect */
  filter: blur(8px);

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0, .2)
  }
}

.heading-block--std > .heading-block__bg {
  filter: blur(0);
  background-color: $c-bigstone;
  background-blend-mode: multiply;

  &::before {
    background: url('../img/bg-about.svg') center/cover;
  }
}

.heading-block__excerpt {
  width: 100%;
}

.heading-block__cta {
  align-self: end;
  height: auto;
}

.heading-block__slider {
  height: auto;
}

.heading-block__content-outer {
  height: 100%;
}

