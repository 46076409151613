.single-block  {
  position: relative;
  z-index: 10;
  display: flex;
  flex-flow: row wrap;

  &:hover .single-block__about {
    background: $c-block-hover;
  }
}

.single-block__title {
  width: 100%;
  margin-bottom: 1rem;
}

.single-block__subtitle {
  margin-bottom: 1rem;
  line-height: 1.1;
}

.single-block__excerpt {
  font-size: 1.5rem;
  margin-bottom: 3rem;
}

.single-block__infos {
  position: relative;
  min-height: 12rem;
  border-top-right-radius: $sb-cpnt-border-radius;

  padding: 3rem 4rem 2rem;
  color: $c-light;

  @media screen and (min-width: $mq-tablet-m) {
    min-height: 20rem;
  }
}

.single-block__infos__bg {
  // z-index: 10;
  border-top-right-radius: $sb-cpnt-border-radius;

  &::after {
    border-top-right-radius: $sb-cpnt-border-radius;
  }
}

.single-block__infos__dates {
  align-self: flex-end;
}

.single-block__cover {
  margin-right: 1rem;
  margin-bottom: 1rem;
  width: 80px;
  height: 130px;
}

.single-block__title--pinned {
  width: 60%;
  font-size: 1.6rem;
}

.single-block__references {
  font-family: $ff-bodytext;
  font-size: 1.4rem;

  p {
    margin-bottom: 0;
  }
}

.single-block__about {
  display: flex;
  flex-wrap: wrap;
  background: $c-light;

  border-bottom-left-radius: $sb-cpnt-border-radius;
  border-bottom-right-radius: $sb-cpnt-border-radius;
  padding: 3rem;
  padding-bottom: 2rem;
  box-shadow: 0 0 10px rgba(0,0,0,.1);
  transition: background .3s $ease-in-out;

  @media screen and (min-width: $mq-desktop-s) {
    padding: 3rem 4rem 2rem;
  }
}

.single-block__about__date {
  margin-bottom: 1rem;
  font-family: $ff-title;
  font-size: 1.6rem;
  font-weight: 600;
}

.single-block__cta {
  align-self: end;
}

// Fix
.single-block:not(.section-three-commitments__item) {
  flex-flow: column wrap;
  justify-content: flex-start;

  .single-block__about {
    // as a parent
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // align-content: flex-start;
    // as a child
    flex-grow: 1;

    [class*="cta"] {
      align-self: flex-end;
    }
  }
}

.single-block.section-three-commitments__item {
  .single-block__infos,
  .single-block__about {
    flex-basis: 100%;
  }
}

.single-block {
  .article__authors {
    margin-bottom: 2rem;
    font-weight: 600;
    
    p {
      margin-bottom: 0;
    }

    b, strong {
      font-weight: 600;
    }

    i {
      font-weight: 500;
    }
  }
}

.single-block__subtitle {
  margin-bottom: 2rem;
  letter-spacing: 0;
}

.single-block__excerpt {
  font-family: $ff-bodytext;
  font-size: 1.8rem;
}

.single-block__infos__dates {
  display: flex;
  align-items: flex-end;
  font-size: 2.4rem;
}

.single-block__infos__day {
  margin-right: 1rem;
  font-size: 6rem;
  line-height: 4.5rem;
}

.single-block__infos__where {
  font-size: 1.8rem;
}

.single-block__infos__dates__MY {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  line-height: 1;
}