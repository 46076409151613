[class*="cta"] {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 5rem;
  margin-bottom: $margin-bot-btn;
  border: $border-style;
  padding: 1rem 2rem;
  border-radius: 8px;
  border-top-left-radius: 0;
  font-family: $ff-title;
  font-size: 1.6rem;
  font-weight: 600;
  color: $c-dark;
  transition: all .3s $ease-in-out;

  .wp-block-button__link {
    width: 100%;
    height: 100%;
    padding: 0;
    background: transparent;
    font-size: 1.8rem;
    text-align: left;
  }

  @media screen and (min-width: $mq-tablet-s) {
    width: $width-btn;
  }

  &::after {
    content: '';
    display: block;
    width: 7px;
    height: 12px;
    transform: translate(25%, 50%);
    background: url('./../img/arrow-sm-btn.svg') center/cover;
    transition: all .3s $ease-in-out;
  }

  &:hover {
    &::after {
      transform: translate(50%, 50%);
    }
  }
}

[class*="cta--"][class*="--wit"] {
  &:hover {
    color: $c-light;
    background: $c-dark;

    &::after {
      background: url('./../img/arrow-sm-btn-wit.svg') center/cover;
    }
  }
}

[class*="cta--"][class*="--dark"] {
  color: $c-light;
  background: $c-dark;

  &::after {
    background: url('./../img/arrow-sm-btn-wit.svg') center/cover;
  }

  &:hover {
    color: $c-dark;
    background: $c-light;

    &::after {
      background: url('./../img/arrow-sm-btn.svg') center/cover;
    }
  }
}

[class*="cta--"][class*="--starship"] {
  color: $c-dark;
  border-color: $c-starship;
  background: $c-starship;

  &::after {
    background: url('./../img/arrow-sm-btn.svg') center/cover;
  }

  &:hover {
    color: $c-starship;
    background: $c-dark;
    border-color: $c-dark;

    &::after {
      background: url('./../img/arrow-sm-btn-starship.svg') center/cover;
    }
  }
}

[class*="cta--"][class*="--apricot"] {
  border-color: $c-apricot;
  color: $c-light;
  background: $c-apricot;

  &::after {
    background: url('./../img/arrow-sm-btn-wit.svg') center/cover;
  }

  &:hover {
    color: $c-dark;
    // color: $c-apricot;
    background: $c-light;

    &::after {
      background: url('./../img/arrow-sm-btn.svg') center/cover;
      // background: url('./../img/arrow-sm-btn-apricot.svg') center/cover;
    }
  }
}

[class*="cta--"][class*="--halfbaked"] {
  border-color: $c-halfbaked;
  color: $c-light;
  background: $c-halfbaked;

  &::after {
    background: url('./../img/arrow-sm-btn-wit.svg') center/cover;
  }

  &:hover {
    color: $c-dark;
    // color: $c-halfbaked;
    background: $c-light;

    &::after {
      background: url('./../img/arrow-sm-btn.svg') center/cover;
      // background: url('./../img/arrow-sm-btn-halfbaked.svg') center/cover;
    }
  }
}

[class*="cta--"][class*="--transparent"] {
  border-color: $c-light;
  color: $c-light;
  background: rgba(0,0,0, 0);

  &::after {
    background: url('./../img/arrow-sm-btn-wit.svg') center/cover;
  }

  &:hover {
    background: rgba(0,0,0, .3);
  }
}

[class*="cta--"][class*="--no-borders"] {
  position: relative;
  display: inline-block;
  width: max-content;
  height: auto;
  margin: 0;
  padding: 0;
  border: 0;

  &::after {
    position: absolute;
    top: 0%;
    right: -2rem;
    // margin-left: 1rem;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: -1rem;
    left: 0;
    transform: translateX(0%);
    display: block;
    width: 0%;
    height: 3px;
    background: $c-dark;
    transition: width .3s $ease-in-out;
  }

  &:hover::before {
    width: calc(100% + 2.5rem);
  }
}

[class*="cta--"][class*="--no-arrow"] {
  &::after {
    display: none;
  }
}
 
// btn archives

[class*="btn-archives"] {
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: $margin-bot-btn;
  font-family: $ff-title;
  font-size: 1.6rem;
  font-weight: 500;
  color: $c-dark;

  // &::before {}
  
  &::after {
    content: '';
    display: block;
  }

  &::after {
    order: 2;
    position: relative;
    width: 40px;
    height: 10px;
    margin-left: 1rem;
    transform: translateY(75%);
    background: url('./../img/arrow-btn.svg') right;
    background-size: 70px 10px;
    background-repeat: no-repeat;
    transition: width .3s $ease-in-out;
  }

  &:hover {
    color: $c-dark;

    &::after {
      width: 55px;
    }
  }
}

.like-btn {
  height: 5rem;
  font-family: $ff-title;
  font-size: 1.6rem;
  font-weight: 500;
  color: $white;
  background: blue;
}