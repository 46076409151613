.magazine-section {
  &__btns {
    > [class*="cta"]:first-of-type {
      @media screen and (min-width: $mq-tablet-s) {
        margin-right: 2rem;
      }
    }

    [class*="cta"] {
      @media screen and (max-width: $mq-tablet-m) {
        flex-grow: 1;
      }
    }
  }

  .main > .flex-container {
    align-items: flex-start;

    @media screen and (max-width: $mq-desktop-s) {
      display: block;
    }

    & > .flex-child {
      @media screen and (min-width: $mq-desktop-s) {
        width: 50%;
      }
    }
  }

  &__discover {
    @media screen and (max-width: $mq-desktop-s) {
      margin-bottom: 4rem;
    }

    @media screen and (min-width: $mq-desktop-s) {
      padding-right: 4rem;
    }
  }
}

.last-magazine {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  @media screen and (min-width: $mq-tablet-s) {
    // justify-content: flex-end;
  }
  
  .flex-child {
    width: 100%;

    @media screen and (min-width: $mq-tablet-s) {
      width: 50%;
      flex-grow: 1;
    }
  }

  &__title-section {
    position: absolute;
    left: 2rem;
    margin-bottom: 3rem;

    @media screen and (min-width: $mq-tablet-s) {
      left: 4rem;
    }
  }

  &__cover {
    // align-self: center;
    width: 100%;
    max-height: 400px;
    max-width: 276px;
    margin-top: 5rem;
    margin-bottom: 2rem;
    
    @media screen and (min-width: $mq-tablet-s) {
      width: 40%;
      margin-right: 2rem;
    }
  }

  &__title {
    margin-bottom: 1rem;
  }

  &__excerpt {
    margin-bottom: 2rem;
  }

  &__content {
    @media screen and (min-width: $mq-tablet-s) {
      margin-top: 5rem;
    }
  }
}