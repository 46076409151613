$margin-footer: 1rem;

.sitemap {
  padding-top: calc( #{$margin-footer} + 1rem );
  padding: 8rem 0 4rem;
  background: $c-dark;

  @media screen and (min-width: $mq-desktop-m) {
    padding: 10rem 0;
  }
}

.sitemap-inner {
  @media screen and (min-width: $mq-desktop-m) {
    display: flex;
  }
}

.sitemap__section, [class*="sitemap__section--"] {
  @media screen and (max-width: $mq-desktop-m) {
    margin-bottom: $margin-footer;
    border-bottom: 1px solid $c-light;
    
    &:last-of-type {
      border-bottom: 0;
    }
  }

  @media screen and (min-width: $mq-tablet-m) {
    margin-bottom: $margin-footer * 1.5;
  }

  @media screen and (min-width: $mq-desktop-m) {
    width: calc(100% / 7 - 4rem);
    flex-grow: 1;

    &:nth-of-type(5) {
      width: calc(100% / 7 + 4rem);
      padding-left: 4rem;
    }

    &:last-of-type {
      border-left: $border-style;
      border-color: $c-light;
      padding-left: 4rem;
    }
  }
}

[class*="sitemap__section--"][class*="--apricot"] {
  @media screen and (max-width: $mq-desktop-m) {
    border-bottom: $border-style;
    border-color: $c-apricot;
  }
}

[class*="sitemap__section--"][class*="--starship"] {
  @media screen and (max-width: $mq-desktop-m) {
    border-bottom: $border-style;
    border-color: $c-starship;
  }

  @media screen and (min-width: $mq-desktop-m) {
    border-left: $border-style;
    border-color: $c-light;
  }
}

.sitemap__section--bonus {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  @media screen and (min-width: $mq-desktop-m) {
    justify-content: flex-start;
  }


}

.sitemap__section__headline-btn {
  @extend %text-uppercase;
  // margin-bottom: $margin-footer;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 1px;
  color: $c-light;
  
  @media screen and (min-width: $mq-tablet-m) {
    &:hover {
      color: $c-halfbaked;
    }
  }
}

.sitemap__section__ls {
  @media screen and (min-width: $mq-tablet-m) {
    margin-bottom: $margin-footer * 1.5;
  }
}

.sitemap__section__el {
  &:first-of-type {
    padding-top: $margin-footer;
  }

  @media screen and (max-width: $mq-desktop-m) {
    display: none;
  }
  margin-bottom: $margin-footer;

  @media screen and (min-width: $mq-desktop-m) {
    margin-bottom: 1rem;
    display: block !important;
  }
}

.sitemap__section__link {
  display: inline-block;
  color: $c-light;
  font-size: 1.4rem;
  font-weight: 300;
  letter-spacing: 0.2rem;

  &::after {
    content: '';
    transform: translateY(2px);
    display: block;
    width: 0%;
    height: 2px;
    background-color: transparent;
    transition: all .3s $ease-in-out;
  }

  @media screen and (min-width: $mq-tablet-m) {
    font-size: 1.6rem;

    &:hover {
      color: $c-light;
  
      &::after {
        display: block;
        width: 100%;
        background-color: $c-light;
      }
    }
  }
}

.sitemap__section__links {
  @media screen and (max-width: $mq-desktop-m) {
    margin:auto;
    margin-right: 0;
  }

  @media screen and (min-width: $mq-tablet-m) {
    &:hover {
      &::after {
        border-bottom: 0;
      }
    }
  }

  .sitemap__section__link {
    margin-right: 1rem;

    @media screen and (min-width: $mq-tablet-m) {
      margin-right: 2rem;
    }

    @media screen and (min-width: $mq-desktop-m) {
      display: block;
      margin-bottom: 1rem;

      &:last-of-type {
        // margin-bottom: 4rem;
      }
    }
  }
}

.sitemap__section__search-input {
  align-self: flex-end;
}

.social {
  margin-left: -1rem;
}

.sitemap__section--bonus .social {
  @media screen and (min-width: $mq-desktop-m) {
    height: 10px;
  }
}

.social__ul {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
}

.footer-end {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding-top: 2rem;

  @media screen and (min-width: $mq-tablet-m) {
    padding-top: 3rem;
  }
}

.footer-end__el {
  margin-bottom: 2rem;
  padding-right: 3rem;
  font-family: $ff-title;
  font-size: 1.1rem;
  font-weight: 500;
  letter-spacing: 0.1rem;

  &:last-of-type {
    margin-right: 0;
  }

  @media screen and (min-width: $mq-tablet-m) {
    margin-bottom: 3rem;
    padding-right: 5rem;
  }
}

.footer__copyrights {
  flex-basis: 100%;
  text-align: center;

  @media screen and (min-width: $mq-tablet-m) {
    flex-basis: initial;
  }
}

.footer-end__el__link {
  font-family: $ff-title;
  font-size: 1.1rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
}

.newsletter-form-container {
  display: none;
}

.footer-end__el__link#newsletter-trigger {
  border: 0;
  cursor: pointer;
}

.footer-end__el__link#newsletter-trigger,
.footer-end__el__link#confidentiality-link {
  position: relative;
  font-weight: 600;

  &::after {
    content:'';
    position: absolute;
    z-index: -1;
    bottom: -1rem;
    display: block;
    height: 2px;
    width: 100%;
    background: $c-dark;
  }
}

.mc4wp-form {
  display: none;
  width: 0;
  height: 0;
  position: fixed;
  z-index: 10000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $c-light;
  border-radius: 3rem;
  padding: 4rem;
  transition: all 1s $ease-in-out;
  font-family: $ff-title;
  font-size: 1.8rem;
  font-weight: lighter;

  @media screen and (min-width: $mq-tablet-m) {
    font-size: 1.8rem;
  }

  &.active {
    display: block;
    width: calc(100vw - 2rem);
    height: auto;
    overflow: scroll;

    @media screen and (min-width: $mq-tablet-l) {
      width: auto;
    }
  }

  input[type="email"] {
    width: 100%;
    border: $border-style;
    border-color: $c-snuff;
    padding: 1rem;
    font-family: $ff-title;
    font-size: 1.6rem;
    margin-bottom: 2rem;
  }

  .newsletter-form__submit-btn {
    align-self: flex-end;
    margin-bottom: 2rem;

    input {
      background: transparent;
      border: 0;
      color: $c-light;
      font-weight: bold;
      transition: color .3s $ease-in-out;
    }

    &:hover input {
      color: $c-dark;
    }
  }

  .newsletter-form__info-msg {
    font-size: 1.4rem;
    margin-bottom: 0;
  }

  .newsletter-form__gdpr {
    font-size: 1.3rem;
    margin-bottom: 2rem;

    p {
      margin-bottom: 1rem;
    }
  }
}

.newsletters-overlay {
  display: none;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,.6);
  opacity: 0;
  transition: opacity 1s $ease-in-out;

  &.active {
    display: block;
    opacity: 1;
  }
}

