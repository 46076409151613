// Composant 'Nos thématiques'
.sb-cpnt__themes__content {
  padding: 4rem 3rem 2rem;
  border-bottom-right-radius: $sb-cpnt-border-radius;
  border-bottom-left-radius: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, .2);
  background: $c-light;
}

.sb-cpnt__themes__els {
  margin-bottom: 2rem;
  border-bottom: $border-style;
  padding-bottom: 2rem;
}

[class*="sb-cpnt__themes__link"] {
  margin: 1.5rem;
  margin-left: 0;
  margin-top: 0;
  padding: 1rem 2rem;
  border-radius: 1rem;
  font-family: $ff-title;
  font-size: 1.4rem;
  font-weight: 800;
  text-transform: uppercase;
  transition: all .3s $ease-in-out;
  
  &[class*="--halfbaked"] {
    background: $c-halfbaked;
    border: $border-style;
    border-color: $c-halfbaked;
    border-bottom-left-radius: 0;

    &:hover {
      color: $c-halfbaked;
    }
  }

  &[class*="--starship"] {
    background: $c-starship;
    border: $border-style;
    border-color: $c-starship;
    border-top-right-radius: 0;

    &:hover {
      color: $c-starship;
    }
  }
  
  &[class*="--apricot"] {
    background: $c-apricot;
    border: $border-style;
    border-color: $c-apricot;

    border-top-left-radius: 0;

    &:hover {
      color: $c-apricot;
    }
  }

  &:hover {
    background: $white !important;
  }
}

.sb-cpnt__tags__el {
  margin-right: 1rem;
  margin-bottom: 0;
}

.sb-cpnt__tags__link {
  margin-bottom: 0;
  font-family: $ff-title;
  font-size: 1.4rem;
  text-decoration: underline;
}