.archive, [class*="archive--"] {
  @media screen and (min-width: $mq-desktop-s) {
    margin-bottom: $page-margin;
  }
}

.archive__item {
  margin-bottom: 4rem;
  border-bottom: $border-style;
  border-bottom-color: $c-snuff;
  padding-bottom: 4rem;

  &:first-of-type {
    border-top: $border-style;
    border-top-color: $c-snuff;
    padding-top: 4rem;
  }

  &:hover {
    .archive__item__link {
      left: 1rem;
    }
  }
}

.archive__item__link {
  position: relative;
  left: 0;
  transition: left .3s $ease-in-out;
}

[class*="archive__item--"] {
  @extend .archive__item;

  &[class*="--media"] {
    display: flex;
  }
}

[class*="archive__item__media-cover"] {
  @extend [class*="single-block__heading"];
  display: none;

  @media screen and (min-width: $mq-tablet-l) {
    display: block;
    flex-shrink: 0;
    align-self: stretch;
    width: 40rem !important;
    height: auto !important;
    // max-height: 30rem;
    margin-right: 4rem;

  }


  &--press {
    @extend [class*="single-block__heading"][class*="--press"];
  }

  &--radio {
    @extend [class*="single-block__heading"][class*="--radio"];
  }
}

.archive--publication {
  .archive__item:first-of-type {
    border-top: 0;
    padding-top: 0;
  }
}

.archive__item__date {
  margin-bottom: .5rem;
  font-size: $ff-title;
  font-size: 1.6rem;
  font-weight: 600;

  @media screen and (min-width: $mq-tablet-l) {
    font-size: 1.8em;
  }
}

.archive__item__title {
  font-size: 3rem;
  // slight offset adjustement
  margin-left: -0.2rem;
  margin-bottom: 1rem;
  
  @media screen and (min-width: $mq-tablet-l) {
    font-size: 3.4rem;
  }
}

.archive__item__subtitle {
  font-size: 2rem;
  margin-bottom: 2rem;

  @media screen and (min-width: $mq-tablet-l) {
    font-size: 2.4rem;
  }

  & + .archive__item__author--media {
    // cancel the margin-bot of prev. sibling
    margin-top: -1rem;
  }
}

.archive__item__author, [class*="archive__item__author--"] {
  p {
    font-size: 1.6rem;
    margin-bottom: 0;

    @media screen and (min-width: $mq-tablet-l) {
      font-size: 1.8rem;
    }
  }
}

[class*="archive__item__author--"][class*="--media"] {
  margin-bottom: 2rem;

  p {
    margin-bottom: 0;
  }
}

.archive__item__event-date {
  margin-bottom: 2rem;
}

.archive__item__excerpt {
  p {
    margin-bottom: 0;
  }
}

[class*="archive__item__excerpt--"][class*="--media"] {
  p {
    margin-bottom: 2rem;
  }
}

.archive__item__dossier {
  margin-bottom: 1rem;
  margin-top: 2rem;
  font-size: 1.4rem;

  &::after, &::before {
    width: 0;
    height: 0;
  }
}






