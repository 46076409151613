.search-filter-inner {
  width: 100%;
  margin-bottom: 8rem;
  border: $border-style;
  border-color: $c-snuff;  
  border-radius: 1rem;
  border-top-right-radius: 0;
  border-top-left-radius: 0;

  @media screen and (min-width: $mq-tablet-m) {
    display: flex;
  }

  @media screen and (min-width: $mq-tablet-l) {
    display: block;
  }

  @media screen and (min-width: $mq-desktop-s) {
    display: flex;
  }
}

.search-filter__head {
  margin-top: 5rem;
  border-top-right-radius: 1rem;
  padding: 1rem 2rem;
  background: $c-snuff;

  button {
    font-family: $ff-title;
    font-size: 1.6rem;
    font-weight: 800;
  }
}

.search-filter__item {
  position: relative;
  flex-grow: 1;
  border-top: $border-style;
  border-top-color: $c-snuff;
  padding: 1rem 2rem;
  padding-right: 0;
  font-family: $ff-title;
  font-size: 1.6rem;

  &::after {
    content: '';
    display: block;
    width: 20px;
    height: 40px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background: $c-light;
  }

  &::before {
    content: '';
    display: block;
    width: 8px;
    height: 12px;
    position: absolute;
    z-index: 2;
    right: 10px;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
    background: url('../img/arrow-sm-btn.svg') center/cover;
  }

  @media screen and (min-width: $mq-tablet-m) {
    width: calc(100% / 6);
    border-top: 0;

    &:not(:first-of-type) {
      border-left: $border-style;
      border-left-color: $c-snuff;
    }

    &:nth-of-type(3) {
      width: calc(100% / 6 + 2rem);
    }
  }
  
  @media screen and (min-width: $mq-tablet-l) {
    width: 100%;
    border-top: $border-style;
    border-top-color: $c-snuff;

    &:not(:first-of-type) {
      border-left: 0;
    }

    &:nth-of-type(3) {
      width: 100%;
    }
  }

  @media screen and (min-width: $mq-desktop-s) {
    width: calc(100% / 6);
    border-top: 0;

    &:not(:first-of-type) {
      border-left: $border-style;
      border-left-color: $c-snuff;
    }

    &:nth-of-type(3) {
      width: calc(100% / 6 + 2rem);
    }
  }

  & > button, & > select {
    width: 100%;
    color: $c-dark;
    font-weight: 600;
  }

  button {
    text-align: left;
  }

  select {
    border: 0;

  }
}

