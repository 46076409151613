.fb-like-container {
  // position: absolute;
  // top: 0;
  // right: 0;
}

.sb-cpnt__fb {
  button {
    flex-basis: 50%;

    &:first-child {
      margin-right: 1rem;
    }
  }

  .cta--wit {
    svg {
      width: 25px;
    }

    .path {
      fill: $c-dark;
      transition: fill .3s $ease-in-out;
    }

    &:hover {
      .path {
        fill: $white;
      }
    }
  }
}

.like-btn {
  border: $border-style;
  border-color: $c-facebook;
  border-radius: 1rem;
  background: $c-facebook;
  transition: all .3s $ease-in-out;

  svg .path {
    fill: $white;
    transition: fill .3s $ease-in-out;
  }

  &:hover {
    color: $c-facebook;
    background: $white;

    svg .path {
      fill: $c-facebook;
    }
  }

  svg {
    margin-right: 1rem;
  }
}