.event {
  padding-bottom: $article-mb;

  p {
    @extend [class*="bodytext"];
    margin-bottom: $article_p-margin-b;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: $ff-title;
    line-height: 1.2;
  }

  h1, .h1 {
    @extend .title-1;
    line-height: 1;
  }

  h2, .h2 {
    @extend .title-2;
  }

  h3, .h3 {
    @extend .title-3;
    font-size: 2.4rem;
    letter-spacing: 0.1rem;
    margin-bottom: $article_titles-margin-b;
  }

  h4, .h4 {
    @extend .title-3;
    font-size: 2rem !important;
    letter-spacing: 0.1rem !important;
    margin-bottom: $article_titles-margin-b * 2;

    @media screen and (min-width: $mq-tablet-m) {
      margin-bottom: $article_titles-margin-b * 3;
    }
  }

  p + h1, p + h2, p + h3, p + h4, p + h5, p + h6 {
    padding-top: $article_chapter-margin-b;
  }

  > p:last-of-type {
    margin-bottom: $article_heading-margin-b;
  }

  .event__couverture,
  .wp-block-cover {
    min-height: auto;
    width: 100%; 
    height: $article_cover-height-mobile;
    margin-bottom: $article_cover-margin-b ;
    
    @media screen and (min-width: $mq-tablet-m) {
      height: $article_cover-height;
    }

    @media screen and (min-width: $mq-desktop-s) {
      margin-bottom: $article_cover-margin-b * 2;
    }
  }
  
  .event__heading {
    margin-bottom: $article_heading-margin-b / 2;

    @media screen and (min-width: $mq-tablet-m) {
      margin-bottom: $article_heading-margin-b;
    }
  }
}

.event__infos {
  margin-bottom: $spacing;

  @media screen and (min-width: $mq-tablet-m) {
    margin-bottom: $spacing * 3;
  }
}

.event__infos__item {
  margin-right: 1rem;
  margin-bottom: .5rem;
  line-height: 1.9rem;

  &.full-width {
    margin-right: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &.flex-container {
    p { 
      @extend .font-infos;
      margin-bottom: 0;
    }
  }
}

.event__infos__item-icon {
  flex-shrink: 0;
  width: 20px;
  margin-right: .5rem;

  @media screen and (min-width: $mq-tablet-m) {
    width: 25px;
  }
}

.event__practical-infos {
  margin-bottom: $spacing * 2;

  @media screen and (min-width: $mq-tablet-m) {
    margin-bottom: $spacing * 3;
  }
}

.event__practical-infos,
.event__practical-infos p {
  font-family: $ff-title;
  font-size: 1.4rem;
  // font-weight: 800;
  line-height: 2;

  @media screen and (min-width: $mq-tablet-m) {
    font-size: 1.8rem;
  }
}

.event__practical-infos__el {
  margin-bottom: 1rem;
}