// Composant 'les plus...'
.sb-cpnt__three__title {
  border-top-left-radius: 0;
}

.sb-cpnt__three__content {
  padding: 4rem 3rem 2rem;
  border-bottom-right-radius: $sb-cpnt-border-radius;
  border-bottom-left-radius: $sb-cpnt-border-radius;
  box-shadow: 0 0 5px rgba(0, 0, 0, .2);
}

.sb-cpnt__three__el {
  margin-bottom: 2rem;
  border-bottom: $border-style;

  &:last-of-type {
    border-bottom: 0;
  }
}

.sb-cpnt__three__el__link {
  margin-bottom: 3rem;
  transition: color .3s $ease-in-out;
}

.sb-cpnt__three__el__title {
  @extend %text-uppercase;
  font-size: 1.8rem;
  font-weight: 300;
  letter-spacing: .1rem;
  line-height: 1;
  margin-bottom: 1rem;

  &--last-mag {
    margin-bottom: 2rem;
  }
}

.sb-cpnt__three__el__author {
  margin-bottom: 2rem;
  font-family: $ff-bodytext;
  font-size: 1.4rem;

  b, strong{
    font-weight: 600;
  }
}

.sb-cpnt__three__el__dossier {
  font-family: $ff-bodytext;
  font-size: 1.8rem;
  font-style: italic;
}

[class*='sb-cpnt__three__el__date'] {
  margin-bottom: 2rem;
  font-family: $ff-alt;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
  
  &[class*="--top"] {
    margin-bottom: .5rem;
  }
}

// Couleurs des blocks
.sb-cpnt__three {
  &--most-read {
    .sb-cpnt__three__content {
      background: $c-wistful;
    }
  }

  &--next-animations,
  &--ecology {
    .sb-cpnt__three__content {
      background: $c-grannyapple;
    }
  }

  &--last-advocacy {
    .sb-cpnt__three__content {
      background: $c-grannyapple-2;
    }
  }

  &--our-mobilizations {
    .sb-cpnt__three__content {
      background: $c-givry;
    }
  }

  &--last-publications {
    .sb-cpnt__three__content {
      background: $c-tropicalblue;
    }
  }

  &--our-stances {
    .sb-cpnt__three__content {
      background: $c-lavendergray;
    }
  }

  &--same-topic,
  &--interculturality {
    .sb-cpnt__three__content {
      background: $c-apricotpeach;
    }
  }

  &--same-author,
  &--democraty {
    .sb-cpnt__three__content {
      background: $c-goldensand;
    }
  }

  &--last-magazines {
    .sb-cpnt__three__content {
      background: $c-starship;
    }
  }
}
