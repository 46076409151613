[class*="single-block__heading"] {
  position: relative;
  z-index: 10;
  width: 100%;
  height: 20rem;
  border-top-right-radius: $sb-cpnt-border-radius;
  box-shadow: 0 -2px 10px rgba(0,0,0,.05);

  &::before {
    content:'';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    
  }

  &[class*="--press"] {
    background: $c-apricot; 
    
    &::before {
      width: 50px;
      height: 50px;
      background: url('../img/press-icon.svg') center/cover;
    }
  }
  
  &[class*="--radio"] {
    background: $c-starship; 

    &::before {
      width: 80px;
      height: 80px;
      background: url('../img/radio-icon.svg') center/cover;
    }
  }
}