[class*="wrapper"] {
  margin: 0 $wrapper-margin-mobile;
  transition: margin .3s $ease-in-out;

  @media screen and (min-width: $mq-tablet-m) {
    margin: 0 $wrapper-margin-tablet;
  }

  @media screen and (min-width: $mq-desktop-xs) {
    margin: 0 $wrapper-margin-desktop;
  }

  @media screen and (min-width: $mq-desktop-m) {
    max-width: $wrapper-width-m;
    margin: auto;
  }
}

[class*="wrapper--"][class*="--big"] {
  @media screen and (min-width: $mq-desktop-m) {
    max-width: $wrapper-width-l;
    margin: auto;
  }
}

[class*="wrapper--"][class*="--small"] {
  @media screen and (min-width: $mq-desktop-s) {
    max-width: $wrapper-width-s;
    margin: auto;
  }
}

.wrapper--fw {
  width: 100%;
}
