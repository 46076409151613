// Global Flex Classes
[class*="flex-container"] {
  display: flex;

  &[class*="--r"] {
    flex-direction: row;
  }

  &[class*="--c"] {
    flex-direction: column;
  }

  &[class*="--w"] {
    flex-wrap: wrap;
  }

  &[class*="--nw"] {
    flex-wrap: nowrap;
  }

  &[class*="--jcc"] {
    justify-content: center;
  }

  &[class*="--jcfs"] {
    justify-content: flex-start;
  }

  &[class*="--jcfe"] {
    justify-content: flex-end;
  }

  &[class*="--jcsb"] {
    justify-content: space-between;
  }
  
  &[class*="--jcsa"] {
    justify-content: space-around;
  }

  &[class*="--aic"] {
    align-items: center;
  }

  &[class*="--aifs"] {
    align-items: flex-start;
  }

  &[class*="--aife"] {
    align-items: flex-end;
  }

  &[class*="--aisb"] {
    align-items: space-between;
  }
  
  &[class*="--aisa"] {
    align-items: space-around;
  }

  &[class*="--ais"] {
    align-items: stretch;
  }
}

// Flexed elements
.visually-hidden {
  @extend %visually-hidden;
}

[class*="mobile-only"] {
  @media screen and (max-width: $mq-tablet-s){
    &[class*="--hidden"] {
      display: none;
    }
  }
}

.not-on-mobile {
  @media screen and (max-width: $mq-tablet-l) {
    display: none !important;
  }
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-transform-initial {
  text-transform: initial;
}

.d-block {
  display: block;
}

.margin-r {
  margin-right: 1rem;
}

.no-margin-bot {
  margin-bottom: 0 !important;
}

.margin-bot {
  margin-bottom: $spacing !important;

  &--big {
    margin-bottom: $spacing * 3 !important;
  }

  &--small {
    margin-bottom: $spacing / 2 !important;
  }
}

.text-decoration-none {
  text-decoration: none;
}

.text-underlined {
  text-decoration: underline;
}

.full-width {
  width: 100%;
}

[class*="bg_fullframe"] {
  position: absolute;
  z-index: -2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &[class*="--black-filter"] {
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.3);
    }
  }
}

.fw-light {
  font-weight: 200;
}

.fw-normal {
  font-weight: normal;
}

.fw-bold {
  font-weight: 700;
}

.no-margin-b {
  margin-bottom: 0 !important;
}

.no-padding-t--on-desktop {
  @media screen and (min-width: $mq-tablet-l) {
    padding-top: 0 !important;
  }
}

